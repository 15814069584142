import logo from './logo.svg';
import './App.css';
 import Homepage from './components/homepage/Homepage'
 import { BrowserRouter, Routes, Route } from "react-router-dom";
 import  ScrollToTop from './utils/ScrollToTop'
 import "aos/dist/aos.css";
 import About from './Aboutpage/About'
import Carrier from './Carrierpage/Carrier';
import Contact from './Contactpage/Contact';
import Privacy from './Privacypage/Privacy';
import Product from './Productpage/Product';
import Service from './servicespage/Service';

function App() {
  return (
    <div className="App">
         <BrowserRouter>
      <ScrollToTop />
      <Routes>
        <Route path="/" element={<Homepage/>}/>
        <Route path="/about" element={<About/>}/>
        <Route path="/carrier" element={<Carrier/>}/>
        <Route path="/contact" element={<Contact/>}/>
        <Route path="/privacy" element={<Privacy/>}/>
        <Route path="/product" element={<Product/>}/>
        <Route path="/Service" element={<Service/>}/>
        </Routes>
        </BrowserRouter>
    </div>
  );
}

export default App;
