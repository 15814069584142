import React, {useEffect} from "react";
import { FaPhoneAlt } from "react-icons/fa";
import { MdOutlineMail } from "react-icons/md";
import { RxHamburgerMenu } from "react-icons/rx";
import { IoSearchOutline } from "react-icons/io5";
import { IoIosMore } from "react-icons/io";
import logo from "../assets/Group 1392.png";
import { Navigate, NavLink, useNavigate } from "react-router-dom";
import AOS from 'aos';
import '../utils/home.css'
import Footer from '../components/Footer/Footer'
import homepic from '../assets/Group.png'
import '../Aboutpage/about.css'
import men from '../assets/professional-male-avatar-profile-picture-employee-work_1322206-66590-removebg-preview 1.png'
import pic1 from '../assets/beautiful-blonde-woman-with-makeup-avatar-for-a-beauty-salon-illustration-in-the-cartoon-style-vector.png'
import pic2 from '../assets/blond-man-with-beard_24877-83058.png'
import pic3 from '../assets/surgeon-doctor-professional-avatar-character-vector-24988572.png'
import pic4 from '../assets/professional-male-avatar-profile-picture-employee-work_1322206-66590.png'
import pic5 from '../assets/beautiful-girl-with-blue-hair-avatar-of-woman-for-social-network-vector.png'
import pic6 from '../assets/17ee935b-c63a-4374-8fc3-91b2559e02f2.png'
import pic7 from '../assets/depositphotos_123041444-stock-illustration-avatar-man-cartoon.png'
import pic8 from '../assets/c387fbad-3783-4224-93a8-f31e818ada30.png'

import pic9 from '../assets/blond-man-young_24877-83197.png'
import pic10 from '../assets/man-face-emotive-icon-smiling-bearded-male-character-in-yellow-flat-illustration-isolated-on-white-happy-human-psychological-portrait-positive-emotions-user-avatar-for-app-web-design-vector.png'
import pic11 from '../assets/man-head-avatar-beautiful-human-face-male-cartoon-vector-31176600.png'
import pic12 from '../assets/surgeon-doctor-professional-avatar-character-vector-24989716.png'
import pic13 from '../assets/elegant-man-in-business-suit-with-badge-man-business-avatar-profile-picture-illustration-isolated-vector.png'
import picgirl from  '../assets/download (31) 1.png'

function About() {

  const navigate = useNavigate()
  const route = window.location.pathname

  useEffect(() => {
    AOS.init({
      duration : 2000
    });
    AOS.refresh();
  }, []);
  return (
    <div>
            <div className="herd-nav">
      {/* Bottom Section */}
      <nav className="navbar navbar-expand-lg">
        <div className="container-fluid">
      <NavLink to="/" className={({ isActive }) => (isActive ? "activetext" : "")}  style={{ textDecoration: 'none'}}>  
          <a className="navbar-brand">
            <img data-aos="fade-up"
              className="img-fluid"
              style={{ maxWidth: "175px" }}
              src={logo}
              alt="site-logo"
            />
            {/* Navbar */}
          </a>
          </NavLink>
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarNav"
            aria-controls="navbarNav"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon">
              <RxHamburgerMenu />
            </span>
          </button>
          <div className="collapse navbar-collapse" id="navbarNav">
            <ul data-aos="fade-up" className="navbar-nav ms-auto">
            <NavLink to="/" className={({ isActive }) => (isActive ? "activetext" : "")}  style={{ textDecoration: 'none'}}>  
            <li className="nav-item">
                <a className="nav-link" aria-current="page" href="#" >
                  Home
                </a>
              </li></NavLink>
              <NavLink to="/product" className={({ isActive }) => (isActive ? "activetext" : "")}  style={{ textDecoration: 'none'}}>  
              <li className="nav-item">
                <a className="nav-link" href="#">
                  Product
                </a>
              </li>
              </NavLink>
              <NavLink   to="/service" className={({ isActive }) => (isActive ? "activetext" : "")}  style={{ textDecoration: 'none'}}>  
              <li className="nav-item">
                <a className="nav-link" href="#">
                  Service
                </a>
              </li>
              </NavLink>
              {/* <NavLink to="/" className={({ isActive }) => (isActive ? "activetext" : "")}  style={{ textDecoration: 'none'}}>  
              <li className="nav-item">
                <a className="nav-link" href="#">
                  <IoSearchOutline size={25} />
                </a>
              </li>
              </NavLink> */}
              <form className="" style={{ paddingRight: "30px" }}>
                <button className="btn btn-sm get-start-btn" type="button">
                <NavLink to="/contact" className={({ isActive }) => (isActive ? "activetext" : "")} style={{ textDecoration: 'none',color:'black'}}> Contact</NavLink>
                </button>
              </form>
              <li className="nav-item">
                <a className="nav-link" href="#">
                  <IoIosMore size={25} />
                </a>
              </li>
            </ul>
          </div>
        </div>
      </nav>
      <div className="container mt-5">
            <div className='row'>
               <div className='col-sm-12 col-md-6 d-flex homepic-center mt-5'>
                    <img src={homepic} className="homepic1"></img>
               </div>
               <div className='col-sm-12 col-md-6 d-flex justify-content-center flex-column mt-5'>
           <div className="d-flex  flex-column justify-content-end mt-3">
           <p className="sectionone-para-1 gradient-text">
                <span data-aos="fade-up" className="kevell-text">About Kevell Corp </span>  
  
              </p>
        
              <p data-aos="fade-up" className="sectionone-para-2 mt-2">
              We strive to be the best place to work, fostering a strong, fun-loving corporate culture that attracts talent looking for long-term careers, not just a stepping-stone. Our focus is on helping customers meet their long-term needs and desires by providing world-class service

              </p>
           </div>
              <div className='d-flex read-center mt-4 '>
              <button data-aos="fade-up" type="button" className="btn read-more-btn" style={{backgroundColor:'rgba(255, 248, 109, 1)'}}>
                Read more &gt;
              </button>
              </div>
            
               </div>
            </div>
  
      </div>

    </div>
    <div className='container'>
    <div className="row mt-5 justify-content-center  align-item-center">
              <div className="col-lg-4 col-md-12 col-sm-12 ">
                     <h3 className="about-head-1 " data-aos="fade-up" >Our Vision :</h3>
                     <p className="about-para-1" data-aos="fade-up" >
                     At Kevell Corp, our vision is to be a global leader in IT services, empowering businesses through innovative technology solutions. We aim to drive digital transformation and deliver exceptional value to our clients by providing cutting-edge, customized IT services that enhance efficiency, foster growth, and ensure long-term success. Our commitment to excellence, integrity, and customer-centricity guides us as we navigate the future of technology.
                     </p>
              </div>
              <div className="col-lg-4 col-md-12 col-sm-12">
              <h3 className="about-head-1" data-aos="fade-up" >Who We Are :</h3>
                     <p className="about-para-1"data-aos="fade-up" >
                     Kevell Corp is a next-generation international IT company that aids enterprises revamp their businesses by adapting to the digital age. We use the tools and technologies available to develop projects that help our customers transform themselves digitally. Apart from being an IT company providing IT services like software development, and networking, we are also a digital marketing agency focused on providing results.
                     </p>
                     </div>
                     <div className="col-lg-4 col-md-12 col-sm-12">
                     <h3 className="about-head-1" data-aos="fade-up" >Our Mission :</h3>
                     <p className="about-para-1" data-aos="fade-up" >
                     At Kevell Corp, our mission is to deliver innovative and reliable IT services that enable our clients to achieve their business goals. We strive to understand the unique needs of each client and provide customized solutions that enhance operational efficiency, foster innovation, and drive growth. 
                     </p>
                     </div>
            </div>
            <div className="row mt-5 justify-content-center  align-item-center">
              <div className="col-lg-2 col-md-2 col-sm-12 ">
                  
              </div>
              <div className="col-lg-4 col-md-12 col-sm-12">
              <h3 className="about-head-1" data-aos="fade-up" > What We Do :</h3>
                     <p className="about-para-1"data-aos="fade-up" >
                     We help enterprises thrive in the digital age, we save them from getting to an inflection point with our IT services like DevOps, big data, cloud computing, automation, and IoT. We afford enterprises the maximum benefit technologies like IoT can provide-we offer a wide range of IoT products. Through our hard work and services, we aid our client businesses to run smoothly. We create websites, and apps and we also provide services to promote the website and apps created through digital marketing. We also do things that come under brand development.
                     </p>
                     </div>
                     <div className="col-lg-4 col-md-12 col-sm-12">
                     <h3 className="about-head-1" data-aos="fade-up" >Our Mission :</h3>
                     <p className="about-para-1" data-aos="fade-up" >
                     Our innovative spirit drives us to continually learn and enhance our skills to better serve our clients. We are passionate, approaching our work each day with profound enthusiasm and dedication. We are committed to respecting our clients, their companies, and their needs.
                     </p>
                     </div>
                     <div className="col-lg-2 col-md-2 col-sm-12 ">
                  
                  </div>
            </div>
    </div>
    {/* <div className='container-fluid mt-5 about-bg'>
    <div className='container'>
        <div className='row '>
            <div className='col-12 d-flex justify-content-center flex-column mt-5'>
                    <h1 className="join-text">Our Leadership Team</h1>
                  <div className="d-flex justify-content-center flex-column align-items-center  mt-3">
                  <img src={men} className="men-pic"></img>
                    <p className="man-name">Jawahar Kesavan
                    </p>
                    <p  className="man-name">Managing Director</p>
                  </div>
            </div>
        </div>
        <div className="row mt-5">
            <div className="col-md-6 col-sm-12 col-lg-3 ">
                <div className="d-flex justify-content-center flex-column align-items-center border-box p-3  mt-4">
                <img src={pic1} className="pic-1"></img>
<p className="man-name"> Srimathi
                    </p>
                    <p  className="man-name">Chief Executive Officer </p>
                </div>


            </div>
            <div className="col-md-6 col-sm-12 col-lg-3 ">
                <div className="d-flex justify-content-center flex-column align-items-center border-box   mt-4 p-3">
                <img src={pic2} className="pic-1"></img>
<p className="man-name"> Ravikumar RajaGopalan
                    </p>
                    <p  className="man-name">
                    Chief Financial Officer </p>
                </div>


            </div>
            <div className="col-md-6 col-sm-12 col-lg-3 ">
                <div className="d-flex justify-content-center flex-column align-items-center border-box   mt-4 p-3">
                <img src={pic3} className="pic-1"></img>
<p className="man-name"> Sridharan
                    </p>
                    <p  className="man-name">
                    Chief Operating Officer </p>
                </div>


            </div>
            <div className="col-md-6 col-sm-12 col-lg-3 ">
                <div className="d-flex justify-content-center flex-column align-items-center border-box   mt-4 p-3">
                <img src={pic4} className="pic-1"></img>
<p className="man-name"> Kishore Ravindran
                    </p>
                    <p  className="man-name">
                    Business Director </p>
                </div>


            </div>
        </div>
        <div className="row mt-5">
            <div className="col-md-6 col-sm-12 col-lg-3 ">
                <div className="d-flex justify-content-center flex-column align-items-center border-box  mt-4 p-3">
                <img src={pic5} className="pic-1 mb-5"></img>

                </div>


            </div>
            <div className="col-md-6 col-sm-12 col-lg-3 ">
                <div className="d-flex justify-content-center flex-column align-items-center border-box p-3  mt-4">
                <img src={pic6} className="pic-1"></img>

                </div>


            </div>
            <div className="col-md-6 col-sm-12 col-lg-3 ">
                <div className="d-flex justify-content-center flex-column align-items-center   mt-4 border-box p-3">
                <img src={pic7} className="pic-1"></img>

                </div>


            </div>
            <div className="col-md-6 col-sm-12 col-lg-3 ">
                <div className="d-flex justify-content-center flex-column align-items-center  mt-4  border-box p-3">
                <img src={pic8} className="pic-1"></img>

                </div>


            </div>
        </div>
        <div className="row mt-5">
            <div className="col-md-6 col-sm-12 col-lg-3 ">
                <div className="d-flex justify-content-center flex-column align-items-center border-box p-3 mt-4">
                <img src={pic9} className="pic-1"></img>

                </div>


            </div>
            <div className="col-md-6 col-sm-12 col-lg-3 ">
                <div className="d-flex justify-content-center flex-column align-items-center border-box p-3 mt-4">
                <img src={pic10} className="pic-1"></img>

                </div>


            </div>
            <div className="col-md-6 col-sm-12 col-lg-3 ">
                <div className="d-flex justify-content-center flex-column align-items-center border-box p-3 mt-4">
                <img src={pic11} className="pic-1"></img>

                </div>


            </div>
            <div className="col-md-6 col-sm-12 col-lg-3 ">
                <div className="d-flex justify-content-center flex-column align-items-center border-box mt-4 p-3">
                <img src={pic12} className="pic-1"></img>

                </div>


            </div>
        </div>
        <div className="row  ">
        <div className=" col-lg-4 col-sm-12 col-md-4  d-flex justify-content-center flex-column align-items-center  mt-5 ">
              
                  </div>
        <div className=" col-lg-4 col-sm-12 col-md-4 d-flex justify-content-center flex-column align-items-center  mt-5 border-box-1 p-2 mb-5">
                  <img src={pic13} className="men-pic"></img>
                    <p className="man-name">Vijayprakash
                    </p>
                    <p  className="man-name">
                    Tech Lead</p>
                  </div>
                  <div className=" col-lg-4 col-sm-12 col-md-4  d-flex justify-content-center flex-column align-items-center  mt-5">
                
                  </div>
        </div>

    </div>

    </div> */}
    <div className='container-fluid mt-5 about-bg'>
      <div className='container'>
        {/* Main Leader */}
        <div className='row'>
          <div className='col-12 d-flex justify-content-center flex-column mt-5' data-aos="fade-up">
            <h1 className="join-text">Our Leadership Team</h1>
            <div className="d-flex justify-content-center flex-column align-items-center mt-3">
              <img src={men} className="men-pic" alt="Leader" />
              <p className="man-name">Jawahar Kesavan</p>
              <p className="man-name">Managing Director</p>
            </div>
          </div>
        </div>

        {/* Leadership Team */}
        <div className="row mt-5">
          {[{ name: "Srimathi", title: "Chief Executive Officer", pic: pic1 }, { name: "Ravikumar RajaGopalan", title: " Chief Financial Officer", pic: pic2 }, { name: "Sridharan", title: " Chief Operating Officer", pic: pic3 }, { name: "Kishore Ravindran", title: "Business Director", pic: pic4 }]
            .map((leader, index) => (
              <div className="col-md-6 col-sm-12 col-lg-3" key={index}>
                <div className="d-flex justify-content-center flex-column align-items-center border-box p-3 mt-4" data-aos="zoom-in">
                  <img src={leader.pic} className="pic-1" alt={leader.name} />
                  <p className="man-name">{leader.name}</p>
                  <p className="man-name">{leader.title}</p>
                </div>
              </div>
            ))}
        </div>

        {/* Additional Leaders */}
        <div className="row mt-5">
          {[pic5, pic6, pic7, pic8].map((pic, index) => (
            <div className="col-md-6 col-sm-12 col-lg-3" key={index}>
              <div className="d-flex justify-content-center flex-column align-items-center border-box mt-4 p-3" data-aos="flip-left">
                <img src={pic} className="pic-1" alt="Leader" />
              </div>
            </div>
          ))}
        </div>

        {/* More Leaders */}
        <div className="row mt-5">
          {[pic9, pic10, pic11, pic12].map((pic, index) => (
            <div className="col-md-6 col-sm-12 col-lg-3" key={index}>
              <div className="d-flex justify-content-center flex-column align-items-center border-box mt-4 p-3" data-aos="flip-left">
                <img src={pic} className="pic-1" alt="Leader" />
              </div>
            </div>
          ))}
        </div>

        {/* Tech Lead */}
        <div className="row">
          <div className="col-lg-4 col-sm-12 col-md-4"></div>
          <div className="col-lg-4 col-sm-12 col-md-4 d-flex justify-content-center flex-column align-items-center mt-5 border-box-1 p-2 mb-5" data-aos="fade-up">
            <img src={pic13} className="men-pic" alt="Tech Lead" />
            <p className="man-name">Vijayprakash</p>
            <p className="man-name">Tech Lead</p>
          </div>
          <div className="col-lg-4 col-sm-12 col-md-4"></div>
        </div>
      </div>
    </div>

    <div className='container-fluid yellow-height'>
      <div className="container about-yellow-bg p-5 mt-5 position-relative">
        <img src={picgirl} className="about-img" alt="About"  />
        <div className="row">
          <div className='col-lg-8 col-md-8 col-sm-12 d-flex justify-content-start'>
            <h1 className='company-text' >
              A company's progress happens when skillful, bold leaders capture the chance to change things for the better.
            </h1>
          </div>
        </div>
      </div>
    </div>






          <Footer/>


    </div>

  
  );
}

export default About;
