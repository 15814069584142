
import React, {useEffect,useState} from "react";
import { FaPhoneAlt } from "react-icons/fa";
import { MdOutlineMail } from "react-icons/md";
import { RxHamburgerMenu } from "react-icons/rx";
import { IoSearchOutline } from "react-icons/io5";
import { IoIosMore } from "react-icons/io";
import logo from "../assets/Group 1392.png";
import { Navigate, NavLink, useNavigate } from "react-router-dom";
import AOS from 'aos';
import '../utils/home.css'
import Footer from '../components/Footer/Footer'
import homepic from '../assets/Group 4439.png'
import { motion } from "framer-motion";

import welpic from '../assets/Group 4364.png'
import picgirl from  '../assets/vibrant-3d-cartoon-illustration-cheerful-young-woman-sitting-crosslegged-floor-with_1243994-10593-removebg-preview 1.png'
import '../Carrierpage/carrier.css'
import { FaUpload } from "react-icons/fa"; 
import '../Productpage/product.css';

import image1 from '../assets/Clip path group.png'
import image2 from '../assets/Group 4565.png'
import image3 from '../assets/Group 4568.png'
import image4 from '../assets/Clip path group (1).png'
import image5 from '../assets/Clip path group (2).png'

import image6 from '../assets/Group 4569.png'
import image7 from '../assets/Group 4570.png'
import image8 from '../assets/Group 4571.png'
import image9 from '../assets/Vector.png'
import image10 from '../assets/Group 4607.png'
function Product() {
  const navigate = useNavigate()
  const route = window.location.pathname
  
  useEffect(() => {
    AOS.init({
      duration: 1200,  // Smooth animations
      offset: 100,     // Animation starts 100px before element enters viewport
      delay: 300,      // Delay animations for a staggered effect
      easing: "ease-out-cubic", // Advanced easing
      mirror: true,    // Keeps animations when scrolling up
      once: false,     // Repeats animation every time it comes into view
    });
    
    AOS.refresh();
  }, []);

  return (
    <div>
            <div className="herd-nav">
      <nav className="navbar navbar-expand-lg">
        <div className="container-fluid">
      <NavLink to="/" className={({ isActive }) => (isActive ? "activetext" : "")}  style={{ textDecoration: 'none'}}>  
          <a className="navbar-brand">
            <img data-aos="fade-up"
              className="img-fluid"
              style={{ maxWidth: "175px" }}
              src={logo}
              alt="site-logo"
            />
            {/* Navbar */}
          </a>
          </NavLink>
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarNav"
            aria-controls="navbarNav"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon">
              <RxHamburgerMenu />
            </span>
          </button>
          <div className="collapse navbar-collapse" id="navbarNav">
            <ul data-aos="fade-up" className="navbar-nav ms-auto">
            <NavLink to="/" className={({ isActive }) => (isActive ? "activetext" : "")}  style={{ textDecoration: 'none'}}>  
            <li className="nav-item">
                <a className="nav-link" aria-current="page" href="#" >
                  Home
                </a>
              </li></NavLink>
              <NavLink to="/product" className={({ isActive }) => (isActive ? "activetext" : "")}  style={{ textDecoration: 'none'}}>  
              <li className="nav-item">
                <a className="nav-link" href="#">
                  Product
                </a>
              </li>
              </NavLink>
              <NavLink  to="/service" className={({ isActive }) => (isActive ? "activetext" : "")}  style={{ textDecoration: 'none'}}>  
              <li className="nav-item">
                <a className="nav-link" href="#">
                  Service
                </a>
              </li>
              </NavLink>
              {/* <NavLink to="/" className={({ isActive }) => (isActive ? "activetext" : "")}  style={{ textDecoration: 'none'}}>  
              <li className="nav-item">
                <a className="nav-link" href="#">
                  <IoSearchOutline size={25} />
                </a>
              </li>
              </NavLink> */}
              <form className="" style={{ paddingRight: "30px" }}>
                <button className="btn btn-sm get-start-btn" type="button">
                <NavLink to="/contact" className={({ isActive }) => (isActive ? "activetext" : "")} style={{ textDecoration: 'none',color:'black'}}> Contact</NavLink>
                </button>
              </form>
              <li className="nav-item">
                <a className="nav-link" href="#">
                  <IoIosMore size={25} />
                </a>
              </li>
            </ul>
          </div>
        </div>
      </nav>
      <div className="container mt-5">
            <div className='row'>
               <div className='col-sm-12 col-md-6 d-flex homepic-center'>
                    <img src={homepic} className="homepic1"></img>
               </div>
               <div className='col-sm-12 col-md-6 d-flex justify-content-center flex-column'>
           <div className="d-flex  flex-column justify-content-end mt-5">
           <p className="sectionone-para-1 gradient-text">
                <span data-aos="fade-up" className="kevell-text">Products</span>
  
              </p>
              <h5 data-aos="fade-up" className="kevell-text-2">Explore the value of our innovative tech products.</h5> 
              <p data-aos="fade-up" className="sectionone-para-2 mt-2">
              In the notion of helping common people, farmers and businessmen we have come up with unique innovative products that make life easier for people. Displayed below is the explanation of Kevells exclusive products.
              </p>
           </div>
  
            
               </div>
            </div>
      </div>

    </div>
    <div className="container">
    <div className="row mt-5">
      <div className="col-lg-6 col-md-6 col-sm-12  ">
        <div className="d-flex  mt-5 gap-4 web-position kevell-box-1 p-3 " data-aos="fade-right">
        <div className="d-flex justify-content-center align-items-center"  >
<img src={image1} style={{width:"80px",height:'70px'}}></img>
        </div>
        <div className='d-flex flex-column kevell-box p-3'>
   <h3 className='web-text-1'>KEVELL CARE</h3>
   <h6 className="world-text">The world's best E-Medicine platform that you can trust</h6>
   <p className='world-para'>Kevell Care is a E-Medicine Platform providing remote delivery of healthcare services, including consultations and diagnosing exams, over the telecommunication infrastructure..</p>
        </div>
        </div>

      </div>
      <div className="col-lg-6 col-md-6 col-sm-12  ">
        <div className="d-flex  mt-5 gap-4 web-position kevell-box-1 p-3" data-aos="fade-left">
        <div className="d-flex justify-content-center align-items-center"  >
<img src={image2} style={{width:"80px",height:'70px'}}></img>
        </div>
        <div className='d-flex flex-column kevell-box p-3'>
   <h3 className='web-text-1'>HOME AUTOMATION</h3>
   <h6 className="world-text">Smart home live with confidence</h6>
   <p className='world-para'> We are in the market to build our own IoT product from Scratch by understanding customer requirements, going through research and giving a Hassle-free solution on Home automation. <span className="white-c">list of best things invented since </span></p>
        </div>
        </div>

      </div>
    </div>
    <div className="row mt-5">
      <div className="col-lg-6 col-md-6 col-sm-12  ">
        <div className="d-flex  mt-5 gap-4 web-position kevell-box-1 p-3" data-aos="fade-right">
        <div className="d-flex justify-content-center align-items-center">
<img src={image3} style={{width:"80px",height:'70px'}}></img>
        </div>
        <div className='d-flex flex-column kevell-box p-3'>
   <h3 className='web-text-1'>KZEUS</h3>
   <h6 className="world-text">Transition of life in a smarter with Kzeus</h6>
   <p className='world-para'>Kzeus is our Device which will connect with all your Home appliances like Light, Fan, Ac, Tv,Heater.etc. and make it smart.</p>
        </div>
        </div>

      </div>
      <div className="col-lg-6 col-md-6 col-sm-12  ">
        <div className="d-flex  mt-5 gap-4 web-position kevell-box-1 p-3" data-aos="fade-left">
        <div className="d-flex justify-content-center align-items-center">
<img src={image4} style={{width:"80px",height:'70px'}}></img>
        </div>
        <div className='d-flex flex-column kevell-box p-3'>
   <h3 className='web-text-1'>SMART WATER CONTROLLER</h3>
   <h6 className="world-text">Easy installation, More Water</h6>
   <p className='world-para'>Smart Water level controller- This device needs to connect with the power supply, connect the sensor wire to your overhead Water Tan  <span className="white-c">list of best things invented since </span></p>
        </div>
        </div>

      </div>
    </div>
    <div className="row mt-5">
      <div className="col-lg-6 col-md-6 col-sm-12">
        <div className="d-flex  mt-5 gap-4 web-position kevell-box-1 p-3" data-aos="fade-right">
        <div className="d-flex justify-content-center align-items-center">
<img src={image5} style={{width:"80px",height:'70px'}}></img>
        </div>
        <div className='d-flex flex-column kevell-box p-3'>
   <h3 className='web-text-1'>AROGYA PREGNANCY</h3>
   <h6 className="world-text">Protect yourself and your baby with Arogya pregnancy app</h6>
   <p className='world-para'>This product we have is a technological guide to pregnant women during their pregnancy period. The app has features to track medical information like weight, blood pressure etc.</p>
        </div>
        </div>

      </div>
      <div className="col-lg-6 col-md-6 col-sm-12  ">
        <div className="d-flex  mt-5 gap-4 web-position kevell-box-1 p-3" data-aos="fade-left">
        <div className="d-flex justify-content-center align-items-center">
<img src={image6} style={{width:"80px",height:'70px'}}></img>
        </div>
        <div className='d-flex flex-column kevell-box p-3'>
   <h3 className='web-text-1'>KIOSK</h3>
   <h6 className="world-text">Advanced healthcare is in front of your remote control.</h6>
   <p className='world-para'>KIOSK - allows patients to be monitored / diagnose / communicate with the doctor remotely in the Kiosk room. <span className="white-c">list of best things ,invented since sliced bread</span></p>
        </div>
        </div>

      </div>
    </div>
    <div className="row mt-5">
      <div className="col-lg-6 col-md-6 col-sm-12  ">
        <div className="d-flex  mt-5 gap-4 web-position kevell-box-1 p-3" data-aos="fade-right">
        <div className="d-flex justify-content-center align-items-center">
<img src={image7} style={{width:"80px",height:'70px'}}></img>
        </div>
        <div className='d-flex flex-column kevell-box p-3'>
   <h3 className='web-text-1'>Dosa Automation</h3>
   <h6 className="world-text">The magic delightful flavour every time</h6>
   <p className='world-para'>we believe our automatic dosa making machine is one among the list of best things invented since sliced bread.<span className="white-c">list of best things invented since sliced bread</span></p>
        </div>
        </div>

      </div>
      <div className="col-lg-6 col-md-6 col-sm-12  ">
        <div className="d-flex  mt-5 gap-4 web-position kevell-box-1 p-3" data-aos="fade-left">
        <div className="d-flex justify-content-center align-items-center">
<img src={image8} style={{width:"80px",height:'70px'}}></img>
        </div>
        <div className='d-flex flex-column kevell-box p-3'>
   <h3 className='web-text-1'>BMI MACHINE</h3>
   <h6 className="world-text">Using advanced products to keep life simple.</h6>
   <p className='world-para'>The offered machines find wide applications in various hospitals, nursing homes, Fitness centres and commercial complexes.<span className="white-c">list of best things invented</span></p>
        </div>
        </div>

      </div>
    </div>
    <div className="row mt-5">
      <div className="col-lg-6 col-md-6 col-sm-12  ">
        <div className="d-flex  mt-5 gap-4 web-position kevell-box-1 p-3" data-aos="fade-right">
        <div className="d-flex justify-content-center align-items-center">
<img src={image9} style={{width:"80px",height:'70px'}}></img>
        </div>
        <div className='d-flex flex-column kevell-box p-3'>
   <h3 className='web-text-1'>KFASS</h3>
   <h6 className="world-text">High-End Agriculture for all</h6>
   <p className='world-para'>KFASS is a solution for agriculture automation.so we provide the hardware and software to make your field smart which can sense the moisture of your field and parallelly control the pump or solenoid valve as well..</p>
        </div>
        </div>

      </div>
      <div className="col-lg-6 col-md-6 col-sm-12  ">
        <div className="d-flex  mt-5 gap-4 web-position kevell-box-1 p-3" data-aos="fade-left">
        <div className="d-flex justify-content-center align-items-center">
<img src={image10} style={{width:"80px",height:'70px'}}></img>
        </div>
        <div className='d-flex flex-column kevell-box p-3'>
   <h3 className='web-text-1'>GYM APP</h3>
   <h6 className="world-text">Get In. Get Fit. Get on with Life</h6>
   <p className='world-para'>The Mobile App helps the individuals to reduce their weights and maintain their health using our recommended exercises.Also it helps track their food calorie info and the reports based on the health data.</p>
        </div>
        </div>

      </div>
    </div>
    </div>


<div className="mt-5">
<Footer/>
</div>
     


    </div>
 

  
  );
}

export default Product;
