
import React, {useEffect,useState} from "react";
import { FaPhoneAlt } from "react-icons/fa";
import { MdOutlineMail } from "react-icons/md";
import { RxHamburgerMenu } from "react-icons/rx";
import { IoSearchOutline } from "react-icons/io5";
import { IoIosMore } from "react-icons/io";
import logo from "../assets/Group 1392.png";
import { Navigate, NavLink, useNavigate } from "react-router-dom";
import AOS from 'aos';
import '../utils/home.css'
import Footer from '../components/Footer/Footer'
import homepic from '../assets/Group 4463.png'
import { motion } from "framer-motion";

import welpic from '../assets/Group 4364.png'
import picgirl from  '../assets/vibrant-3d-cartoon-illustration-cheerful-young-woman-sitting-crosslegged-floor-with_1243994-10593-removebg-preview 1.png'
import '../Carrierpage/carrier.css'
import { FaUpload } from "react-icons/fa"; 
import '../Productpage/product.css';
import '../servicespage/service.css';
import course1 from '../assets/Group 4507.png'
import course2 from '../assets/iot 1.png'
import course3 from '../assets/Group 4510.png'
import course4 from '../assets/Group 4511.png'
import course5 from '../assets/Group 4512.png'
import course6 from '../assets/Group 4513.png'
import course7 from '../assets/Group 4514.png'
import course8 from '../assets/Group 4515.png'
import course9 from '../assets/Group 4516.png'
import course10 from '../assets/Group 4517.png'
import course11 from '../assets/Group 4518.png'
import course12 from '../assets/Group 4519.png'
import course13 from '../assets/Group 4520.png'
import course14 from '../assets/Group 4724.png'
import course15 from '../assets/Group 4726.png'

function Service() {
  const navigate = useNavigate()
  const route = window.location.pathname
  
  useEffect(() => {
    AOS.init({
      duration: 1200,  // Smooth animations
      offset: 100,     // Animation starts 100px before element enters viewport
      delay: 300,      // Delay animations for a staggered effect
      easing: "ease-out-cubic", // Advanced easing
      mirror: true,    // Keeps animations when scrolling up
      once: false,     // Repeats animation every time it comes into view
    });
    
    AOS.refresh();
  }, []);

  return (
    <div>
            <div className="herd-nav">
      {/* Bottom Section */}
      <nav className="navbar navbar-expand-lg">
        <div className="container-fluid">
      <NavLink to="/" className={({ isActive }) => (isActive ? "activetext" : "")}  style={{ textDecoration: 'none'}}>  
          <a className="navbar-brand">
            <img data-aos="fade-up"
              className="img-fluid"
              style={{ maxWidth: "175px" }}
              src={logo}
              alt="site-logo"
            />
            {/* Navbar */}
          </a>
          </NavLink>
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarNav"
            aria-controls="navbarNav"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon">
              <RxHamburgerMenu />
            </span>
          </button>
          <div className="collapse navbar-collapse" id="navbarNav">
            <ul data-aos="fade-up" className="navbar-nav ms-auto">
            <NavLink to="/" className={({ isActive }) => (isActive ? "activetext" : "")}  style={{ textDecoration: 'none'}}>  
            <li className="nav-item">
                <a className="nav-link" aria-current="page" href="#" >
                  Home
                </a>
              </li></NavLink>
              <NavLink to="/product" className={({ isActive }) => (isActive ? "activetext" : "")}  style={{ textDecoration: 'none'}}>  
              <li className="nav-item">
                <a className="nav-link" href="#">
                  Product
                </a>
              </li>
              </NavLink>
              <NavLink  to="/service" className={({ isActive }) => (isActive ? "activetext" : "")}  style={{ textDecoration: 'none'}}>  
              <li className="nav-item">
                <a className="nav-link" href="#">
                  Service
                </a>
              </li>
              </NavLink>
              {/* <NavLink to="/" className={({ isActive }) => (isActive ? "activetext" : "")}  style={{ textDecoration: 'none'}}>  
              <li className="nav-item">
                <a className="nav-link" href="#">
                  <IoSearchOutline size={25} />
                </a>
              </li>
              </NavLink> */}
              <form className="" style={{ paddingRight: "30px" }}>
                <button className="btn btn-sm get-start-btn" type="button">
                <NavLink to="/contact" className={({ isActive }) => (isActive ? "activetext" : "")} style={{ textDecoration: 'none',color:'black'}}> Contact</NavLink>
                </button>
              </form>
              <li className="nav-item">
                <a className="nav-link" href="#">
                  <IoIosMore size={25} />
                </a>
              </li>
            </ul>
          </div>
        </div>
      </nav>
      <div className="container mt-5">
            <div className='row'>
               <div className='col-sm-12 col-md-6 d-flex homepic-center'>
                    <img src={homepic} className="homepic1"></img>
               </div>
               <div className='col-sm-12 col-md-6 d-flex justify-content-center flex-column'>
           <div className="d-flex  flex-column justify-content-end mt-5">
           <p className="sectionone-para-1 gradient-text">
                <span data-aos="fade-up" className="kevell-text">Services</span>
  
              </p>
              <h5 data-aos="fade-up" className="kevell-text-2">Kevell Corp provides IT and SEO services.</h5> 
              <p data-aos="fade-up" className="sectionone-para-2 mt-2">
              Kevell Corp provides IT solutions from DevOps to VR, plus web, app development, and HR services.
              </p>
           </div>
  
            
               </div>
            </div>
      </div>

    </div>
    <div className='container-fluid service-bg'>
    <div className="container">
       <div className='row mt-5'>
        <h1 className="service-text mt-5">Services</h1>
            <div className="col-lg-3 col-md-6 col-sm-12 mt-5">
              <div className='d-flex  flex-column course-box p-3 gap-2'data-aos="flip-up">
           <img src={course1} style={{width:'40px'}}></img>
                   <h3 className="devops-text">Devops</h3>
                   <p className="devops-para">Our team fuses data science and analytics into your solutions, making customised real-time dashboards that make managing business insights simpler.</p>
             <h6 className="learn-more">Learn More</h6>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 col-sm-12 mt-5">
              <div className='d-flex  flex-column course-box p-3  gap-2'data-aos="flip-up">
           <img src={course2} style={{width:'40px'}}></img>
                   <h3 className="devops-text">IoT</h3>
                   <p className="devops-para">Many years of partnership with hardware vendors has helped us to refine, sharpen our engineering skills and intensely understand industry specific problems.
                   Read More </p>
             <h6 className="learn-more">Learn More</h6>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 col-sm-12 mt-5">
              <div className='d-flex  flex-column course-box p-3  gap-2'data-aos="flip-up">
           <img src={course3} style={{width:'40px'}}></img>
                   <h3 className="devops-text">AR/VR</h3>
                   <p className="devops-para">Virtual Reality (VR) creates amazing 3D unnatural,artificial environments and immersive experiences for users. Augmented Reality (AR) creates mind blowing real world, life-like experiences..</p>
             <h6 className="learn-more">Learn More</h6>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 col-sm-12 mt-5">
              <div className='d-flex  flex-column course-box p-3  gap-2'data-aos="flip-up">
           <img src={course4} style={{width:'40px'}}></img>
                   <h3 className="devops-text">Big Data</h3>
                   <p className="devops-para">The app designs that we make are not simple- we make memorable, eye-catching designs that stand apart in the vast pool of designs made by app development companies across the world..</p>
             <h6 className="learn-more">Learn More</h6>
              </div>
            </div>
       </div>
       <div className='row mt-5'>
    
            <div className="col-lg-3 col-md-6 col-sm-12 mt-5">
              <div className='d-flex  flex-column course-box p-3  gap-2'data-aos="flip-up">
           <img src={course5} style={{width:'40px'}}></img>
                   <h3 className="devops-text">Data Science</h3>
                   <p className="devops-para">Our team fuses data science and analytics into your solutions, making customised real-time dashboards that make managing business insights simpler..</p>
             <h6 className="learn-more">Learn More</h6>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 col-sm-12 mt-5">
              <div className='d-flex  flex-column course-box p-3  gap-2'data-aos="flip-up">
           <img src={course6} style={{width:'40px'}}></img>
                   <h3 className="devops-text">Mobile App Development</h3>
                   <p className="devops-para">The app designs that we make are not simple- we make memorable, eye-catching designs that stand apart in the vast pool of designs made by app development companies across the world
                   Read More </p>
             <h6 className="learn-more">Learn More</h6>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 col-sm-12 mt-5">
              <div className='d-flex flex-column course-box p-3  gap-2'data-aos="flip-up">
           <img src={course7} style={{width:'40px'}}></img>
                   <h3 className="devops-text">Web Development</h3>
                   <p className="devops-para">At Kevells we professionally design web apps, websites, and web portals and also give redesigning, and revamping services if required by our clients.</p>
             <h6 className="learn-more">Learn More</h6>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 col-sm-12 mt-5">
              <div className='d-flex  flex-column course-box p-3  gap-2'data-aos="flip-up">
           <img src={course8} style={{width:'40px'}}></img>
                   <h3 className="devops-text">Manage Service Provider</h3>
                   <p className="devops-para">For businesses that might have budget constraints and so are not able to hire in-house IT resources, we provide managed IT services.</p>
             <h6 className="learn-more">Learn More</h6>
              </div>
            </div>
       </div>
       <div className='row mt-5'>
  
            <div className="col-lg-3 col-md-6 col-sm-12 mt-5">
              <div className='d-flex  flex-column course-box p-3  gap-2'data-aos="flip-up">
           <img src={course9} style={{width:'40px'}}></img>
                   <h3 className="devops-text">Security</h3>
                   <p className="devops-para">The increase in the number of technologies like mobile, social media, and big data is affecting protection strategies.</p>
             <h6 className="learn-more">Learn More</h6>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 col-sm-12 mt-5">
              <div className='d-flex  flex-column course-box p-3  gap-2'data-aos="flip-up">
           <img src={course10} style={{width:'40px'}}></img>
                   <h3 className="devops-text">Animation</h3>
                   <p className="devops-para">Within minutes or seconds the attention of customers or audience can be got with 2D animation explainer videos or sales presentations.
                   Read More </p>
             <h6 className="learn-more">Learn More</h6>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 col-sm-12 mt-5">
              <div className='d-flex  flex-column course-box p-3  gap-2'data-aos="flip-up">
           <img src={course11} style={{width:'40px'}}></img>
                   <h3 className="devops-text">Digital Marketing</h3>
                   <p className="devops-para">We are a South India-based digital marketing service providing company who are results-focused.</p>
             <h6 className="learn-more">Learn More</h6>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 col-sm-12 mt-5">
              <div className='d-flex  flex-column course-box p-3  gap-2' data-aos="flip-up">
           <img src={course12} style={{width:'40px'}}></img>
                   <h3 className="devops-text">HR Services</h3>
                   <p className="devops-para">Our company's Hr services include Hr consulting and advisory, Talent acquisition, employee training, Hr audit, and government compliances like payroll management.</p>
             <h6 className="learn-more">Learn More</h6>
              </div>
            </div>
       </div>
       <div className='row mt-5'>
  
<div  className="col-lg-2 col-md-12 col-sm-12 mt-5">

</div>
  <div className="col-lg-3 col-md-6 col-sm-12 mt-5">
    <div className='d-flex  flex-column course-box p-3  gap-2' data-aos="flip-up">
 <img src={course13} style={{width:'40px'}}></img>
         <h3 className="devops-text">Finance Services</h3>
         <p className="devops-para">Kevells provides a full suite of accounting and finance offerings, such as cost forecasting, statutory reporting, financial planning and analysis, and budgeting.
         Read More </p>
   <h6 className="learn-more">Learn More</h6>
    </div>
  </div>

  <div className="col-lg-3 col-md-6 col-sm-12 mt-5">
    <div className='d-flex  flex-column course-box p-3  gap-2' data-aos="flip-up">
 <img src={course14} style={{width:'40px'}}></img>
         <h3 className="devops-text">UI/UX Design</h3>
         <p className="devops-para">The design might be the first thing your customer notices in things related to your brand or business..</p>
   <h6 className="learn-more">Learn More</h6>
    </div>
  </div>
  <div className="col-lg-3 col-md-6 col-sm-12 mt-5">
    <div className='d-flex  flex-column course-box p-3  gap-2' data-aos="flip-up">
 <img src={course15} style={{width:'40px'}}></img>
         <h3 className="devops-text">Graphic Design</h3>
         <p className="devops-para">Graphic designing is creating visual content in the format of illustrations, icons, pictures, typography etc to convey messages.</p>
   <h6 className="learn-more">Learn More</h6>
    </div>
  </div>
  <div  className="col-lg-2 col-md-12 col-sm-12 mt-5">

</div>
</div>
    </div>
    </div>




<div className="mt-5">
<Footer/>
</div>
     


    </div>
 

  
  );
}

export default Service;
