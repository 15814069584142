import React, { useEffect, useState } from "react";
import logo1 from "../../assets/kevell-logo 1 1.png";
import footer1 from "../../assets/indian awards 1.png";
import footer2 from "../../assets/facebook 2.png";
import footer3 from "../../assets/twitter 2.png";
import footer4 from "../../assets/instagram 2.png";
import footer5 from "../../assets/linkedin 2.png";
import footer6 from "../../assets/youtube 2.png";
import footer7 from "../../assets/pinterest 2.png";
import footer8 from "../../assets/isologo 1.png";
import { NavLink } from "react-router-dom";
import '../Footer/Footer.css';

import AOS from 'aos';



function Footer() {

  useEffect(() => {
    AOS.init({
      duration : 2000
    });
    AOS.refresh();
  }, []);
  
  return (
    <div className="footer-main-div">
      <div className="footer">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-12 col-md-12 col-sm-12">
              <div className="d-flex align-items-center">
                <img data-aos="zoom-in"
                  className="img-fluid"
                  style={{ maxWidth: "100px" }}
                  src={logo1}
                  alt="site-logo"
                />
                <p className="footer-company-name">
                  UNIT OF KEVELL GLOBAL SOLUTIONS
                </p>
              </div>
            </div>
            <div className="col-lg-12 col-md-12 col-sm-12">
              <p  className="footer-content">
                Kevell Corp is a leading enterprise service and consulting
                company. We have proven our capabilities in delivering both
                on-shore and off- shore services to global clients with emerging
                technologies.
              </p>
            </div>
            <div className="col-lg-12 col-md-12 col-sm-12 footer-inside-list">
              <ul >
                <li className="footer-useful-links">USEFUL LINKS :</li>
                <NavLink to="/" style={{ textDecoration: 'none' }}><li className="footer-links">Home</li></NavLink>
                <NavLink to="/service" style={{ textDecoration: 'none' }}><li className="footer-links">Service</li></NavLink>
                <NavLink to="/product" style={{ textDecoration: 'none' }}><li className="footer-links">Product</li></NavLink>
              
                <NavLink to="/about" style={{ textDecoration: 'none' }}><li className="footer-links">About Us</li></NavLink>
                <li className="footer-links">Partners</li>
                <NavLink to="/contact" style={{ textDecoration: 'none' }}><li className="footer-links">Contact Us</li></NavLink>
                <NavLink to="/carrier" style={{ textDecoration: 'none' }}><li className="footer-links">Career</li></NavLink>
                <NavLink to="/privacy" style={{ textDecoration: 'none' }}><li className="footer-links">Privacy policy</li></NavLink>
              </ul>
            </div>
            <div className="col-lg-12 col-md-12 col-sm-12 footer-inside-list">
  <ul  className="footer-list">
    <li className="footer-links">
      <a href="https://example.com/indian-awards" target="_blank" rel="noopener noreferrer">
        <img className="" src={footer1} alt="indian-awards" />
      </a>
    </li>
    <li className="footer-links">
      <a href="https://www.facebook.com/kevellcorp/" target="_blank" rel="noopener noreferrer">
        <img className="" src={footer2} alt="facebook" />
      </a>
    </li>
    <li className="footer-links">
      <a href="https://www.facebook.com/kevellcorp/" target="_blank" rel="noopener noreferrer">
        <img className="" src={footer3} alt="twitter" />
      </a>
    </li>
    <li className="footer-links">
      <a href="https://www.instagram.com/kevell_corp/?hl=en" target="_blank" rel="noopener noreferrer">
        <img className="" src={footer4} alt="instagram" />
      </a>
    </li>
    <li className="footer-links">
      <a href="https://www.linkedin.com/company/kevellcorp/posts/?feedView=all" target="_blank" rel="noopener noreferrer">
        <img className="" src={footer5} alt="linkedIn" />
      </a>
    </li>
    <li className="footer-links">
      <a href="https://www.youtube.com/channel/UCKx_sy3b1ocPPA0ueDv6VqA/videos" target="_blank" rel="noopener noreferrer">
        <img className="" src={footer6} alt="youtube" />
      </a>
    </li>
    <li className="footer-links">
      <a href="https://in.pinterest.com/kevell_corp/" target="_blank" rel="noopener noreferrer">
        <img className="" src={footer7} alt="pinterest" />
      </a>
    </li>
    <li className="footer-links">
      <a href="https://example.com/ISO" target="_blank" rel="noopener noreferrer">
        <img className="" src={footer8} alt="ISO" />
      </a>
    </li>
  </ul>
</div>


          </div>
        </div>
      </div>
      <div className="col-lg-12 col-md-12 col-sm-12 footer-bottom-div" >
      <p className="footer-bottom-div-para-1">
  © {new Date().getFullYear()} Kevell Corp
</p>
        <p className="footer-bottom-div-para-2">
          A information technology (IT) service and consulting company
        </p>
      </div>
    </div>
  );
}

export default Footer;
