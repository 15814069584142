import React, {useEffect,useState} from "react";
import brand1 from "../../assets/yobiware card.png";
import brand2 from "../../assets/parry card.png";
import brand3 from "../../assets/atna-technoogies.png";
import brand4 from "../../assets/ticky-techies-logo.png";
import brand5 from "../../assets/simtutitive-logo.png";
import brand6 from "../../assets/e-mechanicz-logo-removebg.png";
import brand7 from "../../assets/csir.png";
import { HiArrowLongRight } from "react-icons/hi2";
// import journey1 from "../../assets/journey.png"
// import journey2 from "../../assets/journey1.png"
// import journey3 from "../../assets/jouney2.png"
// import journey4 from "../../assets/journey4.png"
// import journey5 from "../../assets/journey5.png"
// import journey6 from "../../assets/journey6.png"
import journey1 from "../../assets/1.png";
import journey2 from "../../assets/3.png";
import journey3 from "../../assets/4.png";
import journey4 from "../../assets/5.png";
import journey5 from "../../assets/6.png";
import journey6 from "../../assets/7.png";
import journey7 from "../../assets/Wireframe - 36 (1).png";
import journey8 from "../../assets/Wireframe - 31.png";
import journey9 from "../../assets/Wireframe - 32.png";
import journey10 from "../../assets/Wireframe - 33.png";
import journey11 from "../../assets/Wireframe - 34.png";
import journey12 from "../../assets/Wireframe - 35 (1).png";
import Slider from "react-slick";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/autoplay";
import { Autoplay } from "swiper/modules";




import AOS from 'aos';

function Kevellcards() {
  const [images, setImages] = useState([]);

  useEffect(() => {
    const updateImages = () => {
      const width = window.innerWidth;
      if (width >= 768 && width <= 2000) {
        setImages([journey7, journey8, journey9, journey10, journey11, journey12]);
      } else {
        setImages([journey1, journey2, journey3, journey4, journey5, journey6]);
      }
    };

    updateImages();
    window.addEventListener("resize", updateImages);
    return () => window.removeEventListener("resize", updateImages);
  }, []);

  const settings = {
    dots: false,
    arrows: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
  };

  useEffect(() => {
    AOS.init({
      duration : 2000
    });
    AOS.refresh();
  }, []);

  return (
    <div className="section-5">
      <div className="container">
        <div className="row section-five">
          <div className="col-lg-6 col-md-12 col-12">
            <div className="section-5-main-content">
              <h1 className="section-5-h1" data-aos="fade-up">Our Partners</h1>
              <h2 className="section-5-h2" data-aos="fade-up">
                We are proud to work with partners .
              </h2>
              <h3 className="section-5-h3" data-aos="fade-up">
                They are amazing partners. We are happy in collaborating
                together
              </h3>
            
            </div>
          </div>
          <div className="col-lg-3 col-md-6 col-12 mt-2">
            <div data-aos="fade-up" className="brand-box">
                <div className="d-flex justify-content-center">
                <img
                className="img-fluid"
                style={{ height: "auto", width: "50px" ,display:'flex',justifyContent:'center'}}
                src={brand7}
                alt="brand"
              />
                    </div>
         
              <p >
                The Council of Scientific & Industrial Research (CSIR), known
                for its cutting edge R&D knowledge base in diverse S&T areas
              </p>
              <button  type="button" className="btn section-5-read-more-btn">
                Read more <HiArrowLongRight />
              </button>
            </div>
          </div>
          <div className="col-lg-3 col-md-6 col-12 mt-2">
       
            <div className="brand-box" data-aos="fade-up">
            <div className="d-flex justify-content-center">
          <img className="img-fluid mt-2" src={brand2} alt="brand" />
            </div>
              <p >
                Parry Technology India Private Limited was established in
                Bengaluru, India with the objective of assisting customers
              </p>
              <button  type="button" className="btn section-5-read-more-btn">
                Read more <HiArrowLongRight />
              </button>
            </div>
          </div>
          {/* </div>  */}

          {/* <div className="row mt-2"> */}
          <div className="col-lg-4 col-md-6 col-12 mt-2">
            <div className="brand-box" data-aos="fade-up">
                <div className="d-flex justify-content-center">
                <img
                className="img-fluid mt-4"
                style={{ height: "43px" }}
                src={brand3}
                alt="brand"
              />
                </div>
          
              <p >
                Atna Technologies is a leading enterprise solutions company
                specializing in delivering Microsoft Dynamics 365, Finance &
                Operations,
              </p>
              <button  type="button" className="btn section-5-read-more-btn">
                Read more <HiArrowLongRight />
              </button>
            </div>
          </div>
          <div className="col-lg-4 col-md-6 col-12 mt-2">
            <div data-aos="fade-up" className="brand-box">
            <div className="d-flex justify-content-center">
            <img
                className="img-fluid mt-2"
                style={{ height: "60px" }}
                src={brand6}
                alt="brand"
              />
                </div>
         
              <p >
                Emechanicz Test Solutions Pvt Ltd provides Complete Solutions
                for the Testing and Maintenance of Electronic
              </p>
              <button  type="button" className="btn section-5-read-more-btn">
                Read more <HiArrowLongRight />
              </button>
            </div>
          </div>
          <div className="col-lg-4 col-md-6 col-12 mt-2">
            <div className="brand-box" data-aos="fade-up">
            <div className="d-flex justify-content-center">
            <img 
                className="img-fluid mt-2"
                style={{ height: "auto", width: "150px", paddingTop: "15px" }}
                src={brand5}
                alt="brand"
              />
                </div>
          
              <p >
                Simtuitive is a platform where Corporate Houses and Academic
                Institutions come to buy and implement Engaging
              </p>
              <button  type="button" className="btn section-5-read-more-btn">
                Read more <HiArrowLongRight />
              </button>
            </div>
          </div>



         </div>         
      </div>
      {/* <div className='container-fluid steps-bg'>
         <div className="row g-0 journey-row mt-3" data-aos="fade-up">
         <div className="col-lg-12 col-md-12 col-12">
            <div className="journey-images">
              <img className="img-fluid journey-slide" src={journey1} alt="Journey 1" />
              <img className="img-fluid journey-slide" src={journey2} alt="Journey 2" />
              <img className="img-fluid journey-slide" src={journey3} alt="Journey 3" />
              <img className="img-fluid journey-slide" src={journey4} alt="Journey 4" />
              <img className="img-fluid journey-slide" src={journey5} alt="Journey 5" />
              <img className="img-fluid journey-slide" src={journey6} alt="Journey 6" />
            </div>
            </div>
          </div> 
         </div> */}
           <div className="container-fluid steps-bg">
      <div className="row g-0 journey-row mt-3" data-aos="fade-up">
        <div className="col-lg-12 col-md-12 col-12">
          <div className="journey-images">
            {images.map((src, index) => (
              <img key={index} className="img-fluid journey-slide" src={src} alt={`Journey ${index + 1}`} />
            ))}
          </div>
        </div>
      </div>
    </div>
      {/* <div className="container-fluid steps-bg">
      <div className="row g-0 journey-row mt-3" data-aos="fade-up">
        <div className="col-lg-12 col-md-12 col-12">
          <Swiper
            slidesPerView={1}
            loop={true}
            autoplay={{ delay: 4000, disableOnInteraction: false }}
            modules={[Autoplay]}
            className="journey-images"
          >
            {images.map((src, index) => (
              <SwiperSlide key={index}>
                <img className="img-fluid journey-slide" src={src} alt={`Journey ${index + 1}`} />
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
      </div>
    </div> */}

    </div>
  );
}

export default Kevellcards;
