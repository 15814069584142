
import React, {useEffect,useState} from "react";
import { FaPhoneAlt } from "react-icons/fa";
import { MdOutlineMail } from "react-icons/md";
import { RxHamburgerMenu } from "react-icons/rx";
import { IoSearchOutline } from "react-icons/io5";
import { IoIosMore } from "react-icons/io";
import logo from "../assets/Group 1392.png";
import { Navigate, NavLink, useNavigate } from "react-router-dom";
import AOS from 'aos';
import '../utils/home.css'
import Footer from '../components/Footer/Footer'
import homepic from '../assets/OBJECTS (4).png'
import { motion } from "framer-motion";

import welpic from '../assets/Group 4364.png'
import picgirl from  '../assets/vibrant-3d-cartoon-illustration-cheerful-young-woman-sitting-crosslegged-floor-with_1243994-10593-removebg-preview 1.png'
import '../Carrierpage/carrier.css'
import { FaUpload } from "react-icons/fa"; 
import '../Contactpage/contact.css'
import '../Privacypage/privacy.css'
import net from '../assets/network 1.png'
import net1 from '../assets/cyber-security 1.png'
import static1 from '../assets/external 1.png'
import adver from '../assets/social-media (1) 1.png'
import link1 from '../assets/aggregate-demand (1) 1.png'
import credit from '../assets/atm-card 1.png'
import privacy1 from '../assets/compliant 1.png'
import cookie from '../assets/cookies 1.png'

function Privacy() {
  const navigate = useNavigate()
  const route = window.location.pathname
  
  useEffect(() => {
    AOS.init({
      duration: 1200,  // Smooth animations
      offset: 100,     // Animation starts 100px before element enters viewport
      delay: 300,      // Delay animations for a staggered effect
      easing: "ease-out-cubic", // Advanced easing
      mirror: true,    // Keeps animations when scrolling up
      once: false,     // Repeats animation every time it comes into view
    });
    
    AOS.refresh();
  }, []);
  const sections = [
    {
      img: net,
      title: "Website Visitors",
      text: "Kevell Corp collects non-personal data like browser type and visit timestamps to analyze website usage. IP addresses of logged-in users and commenters are collected and disclosed only under the same conditions as personal data."
    },
    {
      img: net1,
      title: "Security",
      text: "The security of your Personal Information is important to us, but remember that no method of transmission over the Internet, or method of electronic storage is 100% secure. While we strive to use commercially acceptable means to protect your Personal Information, we cannot guarantee its absolute security."
    },
    {
      img: static1,
      title: "Aggregated Statistics",
      text: "Kevell Corp may collect statistics about the behavior of visitors to its website. Kevell Corp may display this information publicly or provide it to others. However, Kevell Corp does not disclose your personally-identifying information."
    },
    {
      img: adver,
      title: "Advertisements",
      text: "Ads on our site may use cookies from partners to collect data for targeted ads. Kevell Corp’s policy covers our cookie use, not that of advertisers."
    },
    {
      img: link1,
      title: "Link To External Sites",
      text: "Our Service may link to third-party sites not operated by us. We recommend reviewing their Privacy Policies, as we are not responsible for their content or practices."
    },
    {
      img: credit,
      title: "Credit & Contact Information",
      text: "This privacy policy was created at privacyterms.io. If you have any questions about our Privacy Policy, please contact us via email or phone."
    },
    {
      img: privacy1,
      title: "Privacy Policy Changes",
      text: "Kevell Corp may update its Privacy Policy occasionally. Please review this page periodically, as continued use of our site implies acceptance of any changes."
    },
    {
      img: cookie,
      title: "Cookies",
      text: "A cookie is a small file stored on a visitor’s computer and sent to the website on return. Kevell Corp uses cookies to track usage and preferences."
    }
  ];
  return (
    <div>
            <div className="herd-nav">
      {/* Bottom Section */}
      <nav className="navbar navbar-expand-lg">
        <div className="container-fluid">
      <NavLink to="/" className={({ isActive }) => (isActive ? "activetext" : "")}  style={{ textDecoration: 'none'}}>  
          <a className="navbar-brand">
            <img data-aos="fade-up"
              className="img-fluid"
              style={{ maxWidth: "175px" }}
              src={logo}
              alt="site-logo"
            />
            {/* Navbar */}
          </a>
          </NavLink>
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarNav"
            aria-controls="navbarNav"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon">
              <RxHamburgerMenu />
            </span>
          </button>
          <div className="collapse navbar-collapse" id="navbarNav">
            <ul data-aos="fade-up" className="navbar-nav ms-auto">
            <NavLink to="/" className={({ isActive }) => (isActive ? "activetext" : "")}  style={{ textDecoration: 'none'}}>  
            <li className="nav-item">
                <a className="nav-link" aria-current="page" href="#" >
                  Home
                </a>
              </li></NavLink>
              <NavLink to="/product" className={({ isActive }) => (isActive ? "activetext" : "")}  style={{ textDecoration: 'none'}}>  
              <li className="nav-item">
                <a className="nav-link" href="#">
                  Product
                </a>
              </li>
              </NavLink>
              <NavLink  to="/service" className={({ isActive }) => (isActive ? "activetext" : "")}  style={{ textDecoration: 'none'}}>  
              <li className="nav-item">
                <a className="nav-link" href="#">
                  Service
                </a>
              </li>
              </NavLink>
              {/* <NavLink to="/" className={({ isActive }) => (isActive ? "activetext" : "")}  style={{ textDecoration: 'none'}}>  
              <li className="nav-item">
                <a className="nav-link" href="#">
                  <IoSearchOutline size={25} />
                </a>
              </li>
              </NavLink> */}
              <form className="" style={{ paddingRight: "30px" }}>
                <button className="btn btn-sm get-start-btn" type="button">
                <NavLink to="/contact" className={({ isActive }) => (isActive ? "activetext" : "")} style={{ textDecoration: 'none',color:'black'}}> Contact</NavLink>
                </button>
              </form>
              <li className="nav-item">
                <a className="nav-link" href="#">
                  <IoIosMore size={25} />
                </a>
              </li>
            </ul>
          </div>
        </div>
      </nav>
      <div className="container mt-5">
            <div className='row'>
               <div className='col-sm-12 col-md-6 d-flex homepic-center'>
                    <img src={homepic} className="homepic1"></img>
               </div>
               <div className='col-sm-12 col-md-6 d-flex justify-content-center flex-column'>
           <div className="d-flex  flex-column justify-content-end mt-3">
           <p className="sectionone-para-1 gradient-text">
                <span data-aos="fade-up" className="kevell-text">Privacy Policy</span>
  
              </p>
              <h4 data-aos="fade-up" className="kevell-text-2">Your Privacy Is Important To Us</h4> 
              <p data-aos="fade-up" className="sectionone-para-2 mt-2">
              Kevell Corp respects your privacy and is committed to protecting any personal information collected through our website,<span className="www">www.kevellcorp.com.</span> This policy outlines what data we collect, how we use it, and when we may share it. It applies only to information gathered via our website and not from other sources.
              </p>
           </div>
  
            
               </div>
            </div>
      </div>

    </div>

   {/* <div className="container ">
    <div className="row mt-5">
      <div className="col-lg-6 col-md-6 col-sm-12 d-flex  mt-5 gap-4 web-position ">
        <div>
         <img src={net} className="netpic"></img>
        </div>
        <div className='d-flex flex-column'>
   <h3 className='web-text'>Website Visitors</h3>
   <p className='web-para'>Kevell Corp collects non-personal data like browser type and visit timestamps to analyze website usage. IP addresses of logged-in users and commenters are collected and disclosed only under the same conditions as personal data.</p>
        </div>
      </div>
      <div className="col-lg-6 col-md-6 col-sm-12 d-flex  mt-5 gap-4 web-position ">
        <div>
         <img src={net1} className="netpic"></img>
        </div>
        <div className='d-flex flex-column'>
   <h3 className='web-text'>Security </h3>
   <p className='web-para'>The security of your Personal Information is important to us, but remember that no method of transmission over the Internet, or method of electronic storage is 100% secure. While we strive to use commercially acceptable means to protect your Personal Information, we cannot guarantee its absolute security</p>
        </div>
      </div>
    </div>
    <div className="row mt-5">
      <div className="col-lg-6 col-md-6 col-sm-12 d-flex  mt-5 gap-4 web-position ">
        <div>
         <img src={static1} className="netpic"></img>
        </div>
        <div className='d-flex flex-column'>
   <h3 className='web-text'>Aggregated Statistics</h3>
   <p className='web-para'>Kevell Corp may collect statistics about the behavior of visitors to its website. Kevell Corp may display this information publicly or provide it to others. However, Kevell Corp does not disclose your personally-identifying information</p>
        </div>
      </div>
      <div className="col-lg-6 col-md-6 col-sm-12 d-flex  mt-5 gap-4 web-position ">
        <div>
         <img src={adver} className="netpic"></img>
        </div>
        <div className='d-flex flex-column'>
   <h3 className='web-text'>Advertisements </h3>
   <p className='web-para'>Ads on our site may use cookies from partners to collect data for targeted ads. Kevell Corp’s policy covers our cookie use, not that of advertisers.
   4o</p>
        </div>
      </div>
    </div>
    <div className="row mt-5">
      <div className="col-lg-6 col-md-6 col-sm-12 d-flex  mt-5 gap-4 web-position ">
        <div>
         <img src={link1} className="netpic"></img>
        </div>
        <div className='d-flex flex-column'>
   <h3 className='web-text'>Link To External Sites</h3>
   <p className='web-para'>Our Service may link to third-party sites not operated by us. We recommend reviewing their Privacy Policies, as we are not responsible for their content or practices.</p>
        </div>
      </div>
      <div className="col-lg-6 col-md-6 col-sm-12 d-flex  mt-5 gap-4 web-position ">
        <div>
         <img src={credit} className="netpic"></img>
        </div>
        <div className='d-flex flex-column'>
   <h3 className='web-text'>Credit & Contact Information </h3>
   <p className='web-para'>This privacy policy was created at privacyterms.io. If you have any questions about our Privacy Policy, please contact us via email or phone</p>
        </div>
      </div>
    </div>

    <div className="row mt-5">
      <div className="col-lg-6 col-md-6 col-sm-12 d-flex  mt-5 gap-4 web-position ">
        <div>
         <img src={privacy1} className="netpic"></img>
        </div>
        <div className='d-flex flex-column'>
   <h3 className='web-text'>Privacy Policy Changes</h3>
   <p className='web-para'>Kevell Corp may update its Privacy Policy occasionally. Please review this page periodically, as continued use of our site implies acceptance of any changes.</p>
        </div>
      </div>
      <div className="col-lg-6 col-md-6 col-sm-12 d-flex  mt-5 gap-4 web-position ">
        <div>
         <img src={cookie} className="netpic"></img>
        </div>
        <div className='d-flex flex-column'>
   <h3 className='web-text'>Cookies </h3>
   <p className='web-para'>A cookie is a small file stored on a visitor’s computer and sent to the website on return. Kevell Corp uses cookies to track usage and preferences. </p>
        </div>
      </div>
    </div>
  </div>  */}
    <div className="container">
      <div className="row mt-5">
        {sections.map((section, index) => (
          <div
            key={index}
            className="col-lg-6 col-md-6 col-sm-12 d-flex mt-5 gap-4 web-position"
            data-aos={index % 2 === 0 ? "fade-right" : "fade-left"}
          >
            <div>
              <img src={section.img} className="netpic" alt={section.title} />
            </div>
            <div className='d-flex flex-column'>
              <h3 className='web-text'>{section.title}</h3>
              <p className='web-para'>{section.text}</p>
            </div>
          </div>
        ))}
      </div>
    </div>
<div className="mt-5">
<Footer/>
</div>
     


    </div>
 

  
  );
}

export default Privacy;
