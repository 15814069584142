
import React, {useEffect,useState} from "react";
import { FaPhoneAlt } from "react-icons/fa";
import { MdOutlineMail } from "react-icons/md";
import { RxHamburgerMenu } from "react-icons/rx";
import { IoSearchOutline } from "react-icons/io5";
import { IoIosMore } from "react-icons/io";
import logo from "../assets/Group 1392.png";
import { Navigate, NavLink, useNavigate } from "react-router-dom";
import AOS from 'aos';
import '../utils/home.css'
import Footer from '../components/Footer/Footer'
import homepic from '../assets/OBJECTS (5).png'

import welpic from '../assets/Group 4364.png'
import picgirl from  '../assets/vibrant-3d-cartoon-illustration-cheerful-young-woman-sitting-crosslegged-floor-with_1243994-10593-removebg-preview 1.png'
import '../Carrierpage/carrier.css'
import { FaUpload } from "react-icons/fa"; 
import '../Contactpage/contact.css'

function Contact() {
  const navigate = useNavigate()
  const route = window.location.pathname
  const [fileName, setFileName] = useState("No file choosen");

  const [formData, setFormData] = useState({
    name: "",
    email: "",
    mobile: "",
    subject: "",
    description: "",
  });

  const [errors, setErrors] = useState({});

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

 


  const validateForm = () => {
    let newErrors = {};
    if (!formData.name) newErrors.name = "Name is required";
    if (!formData.email) newErrors.email = "Email is required";
    if (!formData.mobile) newErrors.mobile = "Mobile is required";
 
    if (!formData.description) newErrors.description = "Description is required";
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validateForm()) {
      console.log("Form submitted:", formData);
    }
  };
  useEffect(() => {
    AOS.init({
      duration : 2000
    });
    AOS.refresh();
  }, []);
  return (
    <div>
            <div className="herd-nav">
      {/* Bottom Section */}
      <nav className="navbar navbar-expand-lg">
        <div className="container-fluid">
      <NavLink to="/" className={({ isActive }) => (isActive ? "activetext" : "")}  style={{ textDecoration: 'none'}}>  
          <a className="navbar-brand">
            <img data-aos="fade-up"
              className="img-fluid"
              style={{ maxWidth: "175px" }}
              src={logo}
              alt="site-logo"
            />
            {/* Navbar */}
          </a>
          </NavLink>
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarNav"
            aria-controls="navbarNav"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon">
              <RxHamburgerMenu />
            </span>
          </button>
          <div className="collapse navbar-collapse" id="navbarNav">
            <ul data-aos="fade-up" className="navbar-nav ms-auto">
            <NavLink to="/" className={({ isActive }) => (isActive ? "activetext" : "")}  style={{ textDecoration: 'none'}}>  
            <li className="nav-item">
                <a className="nav-link" aria-current="page" href="#" >
                  Home
                </a>
              </li></NavLink>
              <NavLink to="/product" className={({ isActive }) => (isActive ? "activetext" : "")}  style={{ textDecoration: 'none'}}>  
              <li className="nav-item">
                <a className="nav-link" href="#">
                  Product
                </a>
              </li>
              </NavLink>
              <NavLink   to="/service" className={({ isActive }) => (isActive ? "activetext" : "")}  style={{ textDecoration: 'none'}}>  
              <li className="nav-item">
                <a className="nav-link" href="#">
                  Service
                </a>
              </li>
              </NavLink>
              {/* <NavLink to="/" className={({ isActive }) => (isActive ? "activetext" : "")}  style={{ textDecoration: 'none'}}>  
              <li className="nav-item">
                <a className="nav-link" href="#">
                  <IoSearchOutline size={25} />
                </a>
              </li>
              </NavLink> */}
              <form className="" style={{ paddingRight: "30px" }}>
                <button className="btn btn-sm get-start-btn" type="button">
                <NavLink to="/contact" className={({ isActive }) => (isActive ? "activetext" : "")} style={{ textDecoration: 'none',color:'black'}}> Contact</NavLink>
                </button>
              </form>
              <li className="nav-item">
                <a className="nav-link" href="#">
                  <IoIosMore size={25} />
                </a>
              </li>
            </ul>
          </div>
        </div>
      </nav>
      <div className="container mt-5">
            <div className='row'>
               <div className='col-sm-12 col-md-6 d-flex homepic-center'>
                    <img src={homepic} className="homepic1"></img>
               </div>
               <div className='col-sm-12 col-md-6 d-flex justify-content-center flex-column'>
           <div className="d-flex  flex-column justify-content-end mt-3">
           <p className="sectionone-para-1 gradient-text">
                <span data-aos="fade-up" className="kevell-text">Contact Enquiry</span>
  
              </p>
              <h4 data-aos="fade-up" className="kevell-text-2">Want to become one amongst our employees?</h4> 
              <p data-aos="fade-up" className="sectionone-para-2 mt-2">
              Kevell Corp is no doubt an exciting,reliable place to work at. A place where people join hands together,collaborate with colleagues in a friendly manner to achieve success. If you wish to meet our people and work together.
              </p>
           </div>
  
            
               </div>
            </div>
      </div>

    </div>

    <div className="container-fluid form-position">
        <div className="container">
            <div className="row mt-0">
                <div className="col-12 d-flex justify-content-center flex-column">
                   <h1 className="project-text">Got A Project In Mind?</h1>
                   <h4 className="project-text-1"> Let’s Talk</h4>
                </div>
            </div>
        </div>
    <div className="container mt-5 p-5 border shadow  form-bg ">
         
      <h2 className="text-center mb-4 your-text">Your  Details</h2>
      <form onSubmit={handleSubmit}>
        {/* Name Field */}
    

        {/* Email & Mobile Fields */}
        <div className="row mb-3 ">
 
          <div className="col-md-6 mt-4">
          <label className="form-label">
            Name <span className=" red-icon">*</span>
          </label>
          <input
            type="text"
            name="name"
            className={`form-control  form-con ${errors.name ? "is-invalid" : ""}`}
            placeholder="Enter your name"
            value={formData.name}
            onChange={handleChange}
          />
          {errors.name && <div className="invalid-feedback">{errors.name}</div>}
          </div>
          <div className="col-md-6 mt-4">
            <label className="form-label">
              Email <span className="text-danger">*</span>
            </label>
            <input
              type="email"
              name="email"
              className={`form-control form-con ${errors.email ? "is-invalid" : ""}`}
              placeholder="Enter your email"
              value={formData.email}
              onChange={handleChange}
            />
            {errors.email && <div className="invalid-feedback">{errors.email}</div>}
          </div>
        </div>

        <div className="mb-3 mt-4">
        <label className="form-label">
            Subject <span className=" red-icon">*</span>
          </label>
          <input
            type="text"
            name="subject"
            className={`form-control form-con ${errors.subject ? "is-invalid" : ""}`}
            placeholder=" subject"
            value={formData.subject}
            onChange={handleChange}
          />
          {errors.subject && <div className="invalid-feedback">{errors.subject}</div>}
        </div>


        {/* Description */}
        <div className="mb-3 mt-4">
          <label className="form-label">
    
          </label>
          <textarea
            name="description"
            className={`form-control form-des-1 form-con ${errors.description ? "is-invalid" : ""}`}
            rows="3"
            placeholder="What are you looking for?"
            value={formData.description}
            onChange={handleChange}
          ></textarea>
          {errors.description && <div className="invalid-feedback">{errors.description}</div>}
        </div>

        {/* Submit Button */}
        {/* <div className="d-flex justify-content-center"> 
        <button type="submit" className="btn submit-btn">
          Submit
        </button>
        </div> */}
     
      </form>
    </div>
    </div>
<div className="container-fluid form-position">
<div className="container">
            <div className="row mt-5">
                <div className="col-12 d-flex justify-content-center flex-column">
                   <h1 className="project-text">Find Us :</h1>
                   <h4 className="project-text-1">We're here for you.</h4>
                </div>
            </div>
            <div className="row mt-5 justify-content-center  align-item-center">
              <div className="col-lg-3 col-md-3 col-sm-12 ">
                     <h3 className="about-head-1 " data-aos="fade-up" >Madurai</h3>
                     <p className="about-para-1" data-aos="fade-up" >
                     Head Office
                     Chitra Madhavan Enclave, Hakim AjmalKhan Road (Near Lady Doak College Back Gate), Chinna Chokkikulam, Madurai-625002
                     </p>
              </div>
              <div className="col-lg-3 col-md-3 col-sm-12">
              <h3 className="about-head-1" data-aos="fade-up" > Palani</h3>
                     <p className="about-para-1"data-aos="fade-up" >
                     Branch Office
146/1-E Dindigul palani main road, Near Dharmar mahal, Sivagiripatti, Palani - 624601.
                     </p>
                     </div>
                     <div className="col-lg-3 col-md-3 col-sm-12">
                     <h3 className="about-head-1" data-aos="fade-up" > Tirunelveli</h3>
                     <p className="about-para-1" data-aos="fade-up" >
                     Branch Office
                     54/F 4-1, Sarah tucker college road, Perumal puram, Tirunelveli - 627007
                     </p>
                     </div>
                     <div className="col-lg-3 col-md-3 col-sm-12">
                     <h3 className="about-head-1" data-aos="fade-up" >Kerala</h3>
                     <p className="about-para-1" data-aos="fade-up" >
                     2nd floor, Tharamel building, PRA 47, Padivattom, Edappally, Ernakulam, Kerala 682024
                     </p>
                     </div>
            </div>
            
        </div>
  

     


    </div>
    <div className="mt-5">
<Footer/>
</div>
</div>
 

  
  );
}

export default Contact;
