import React, { useState, useEffect } from "react";
import { FaArrowRight } from "react-icons/fa";
import tech1 from "../../assets/XMLID_233_.png";
import tech2 from "../../assets/OBJECTS (1).png";
import tech3 from "../../assets/OBJECTS (2).png";
import tech4 from "../../assets/Group 4331.png";
import tech5 from "../../assets/Group 4332.png";
import tech6 from "../../assets/Group 4333.png";
import tech7 from "../../assets/Group 4334.png";
import tech8 from "../../assets/Group 4336.png";
import tech9 from "../../assets/Group 4335.png";

import AOS from 'aos';

function Kevellsection() {
  const tabs = [
    "Kevell Care",
    "Kevell Kitchen",
    "Kevell Corp",
    "Kzeus",
    "Kevell Guru",
    "Ayutta",
    "Golden Mavros",
    "HRMS",
    "Kippo Campus",
  
  ];
  const [activeTab, setActiveTab] = useState("DevOps");
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % tabs.length);
    }, 3000); // Change tab every 3 seconds
    return () => clearInterval(interval); // Cleanup interval on unmount
  }, [tabs.length]);

  useEffect(() => {
    setActiveTab(tabs[currentIndex]);
  }, [currentIndex]);

  useEffect(() => {
    AOS.init({
      duration : 2000
    });
    AOS.refresh();
  }, []);
  const handleClick1 = () => {
    window.open("https://www.kevell.care/", "_blank");
  };
  
  const handleClick2 = () => {
    window.open("https://kevellkitchen.com/#/Home", "_blank");
  };
  
  const handleClick3 = () => {
    window.open("https://kevellcorp.com/", "_blank");
  };
  
  const handleClick4 = () => {
    window.open("https://kzeus.io/", "_blank");
  };
  
  const handleClick5 = () => {
    window.open("https://www.kevellguru.org/#/Home", "_blank");
  };
  
  const handleClick6 = () => {
    window.open("https://kevelldigital.com/ayutta/#/", "_blank");
  };
  
  const handleClick7 = () => {
    window.open("https://goldenmavros.com/index.html", "_blank");
  };
  
  const handleClick8 = () => {
    window.open("https://kuantaime.com/hrms/", "_blank");
  };
  
  const handleClick9 = () => {
    window.open("https://kippocamp.com/", "_blank");
  };
  const renderContent = () => {
    switch (activeTab) {
      case "Kevell Care":
        return (
          <div className="content">
            <img src={tech1} alt="Kevell Care" className="carousel-image" />
            <h1>Kevell Care</h1>
            <p className="sectionfour-para-2">
            Kevell care allows health care providers to evaluate, diagnose and treat Patients without the need for an in-person visit.
            </p>
            <div className='d-flex read-center mt-5'>
            <button type="submit" class="btn read-more-btn" style={{background:'#C3EAD8'}}  onClick={handleClick1}>
              Read more <FaArrowRight />
            </button>
            </div>
         
          </div>
        );
      case "Kevell Kitchen":
        return (
          <div className="content">
            <img src={tech2} alt="Kevell Kitchen" className="carousel-image" />
            <h1>Kevell Kitchen</h1>
            <p className="sectionfour-para-2">
            Kevell Kitchen is a food processing machine and kitchen equipment manufacturer in Madurai, India. They make machines for dosa, vada, samosa, chapati, chikki, and more
            </p>
            <div className='d-flex read-center mt-5'>
            <button type="submit" class="btn read-more-btn" style={{background:'#C3EAD8'}} onClick={handleClick2}>
              Read more <FaArrowRight />
            </button>
            </div>
          </div>
        );
      case "Kevell Corp":
        return (
          <div className="content">
            <img src={tech3} alt="Kevell Corp" className="carousel-image" />
            <h1>Kevell Corp </h1>
            <p className="sectionfour-para-2">
            Kevell care allows health care providers to evaluate, diagnose and treat Patients without the need for an in-person visit.
            </p>
            <div className='d-flex read-center'>
            <button type="submit" class="btn read-more-btn"  style={{background:'#C3EAD8'}} onClick={handleClick3}>
              Read more <FaArrowRight />
            </button>
            </div>
          </div>
        );
      case "Kzeus":
        return (
          <div className="content">
            <img src={tech4} alt="Kzeus" className="carousel-image" />
            <h1>Kzeus</h1>
            <p className="sectionfour-para-2">
            The IoT offers businesses unprecedented oppurtunities for gathering data and automating the process
            </p>
            <div className='d-flex read-center mt-5'>
            <button type="submit" class="btn read-more-btn"  style={{background:'#C3EAD8'}} onClick={handleClick4}>
              Read more <FaArrowRight />
            </button>
            </div>
          </div>
        );
      case "Kevell Guru":
        return (
          <div className="content">
            <img src={tech5} alt="Kevell Guru" className="carousel-image" />
            <h1>Kevell Guru</h1>
            <p className="sectionfour-para-2">
            Kevell Guru is a tech institute in Madurai, Tamil Nadu that offers internships, training, mentorship, and coaching 
            </p>
            <div className='d-flex read-center mt-5'>
            <button type="submit" class="btn read-more-btn"  style={{background:'#C3EAD8'}} onClick={handleClick5}>
              Read more <FaArrowRight />
            </button>
            </div>
          </div>
        );
      case "Ayutta":
        return (
          <div className="content">
            <img src={tech6} alt="Ayutta" className="carousel-image" />
            <h1>Ayutta</h1>
            <p className="sectionfour-para-2">
            Kevell Guru is a tech institute in Madurai, Tamil Nadu that offers internships, training, mentorship, and coaching 
            </p>
            <div className='d-flex read-center mt-5'>
            <button type="submit" class="btn read-more-btn"  style={{background:'#C3EAD8'}} onClick={handleClick6}>
              Read more <FaArrowRight />
            </button>
            </div>
          </div>
        );
      case "Golden Mavros":
        return (
          <div className="content">
            <img
              src={tech7}
              alt="Managed Service Provider"
              className="carousel-image img-fluid"
            />
            <h1>Golden Mavros</h1>
            <p className="sectionfour-para-2">
            Golden Mavros is a media company that specializes in digital creativity, brand building, and storytelling. 
            </p>
            <div className='d-flex read-center mt-5'>
            <button type="submit" class="btn read-more-btn"  style={{background:'#C3EAD8'}} onClick={handleClick7}>
              Read more <FaArrowRight />
            </button>
            </div>
          </div>
        );
      case "HRMS":
        return (
          <div className="content">
            <img src={tech8} alt="HRMS" className="carousel-image" />
            <h1>HRMS</h1>
            <p className="sectionfour-para-2">
            Kevell Guru is a tech institute in Madurai, Tamil Nadu that offers internships, training, mentorship, and coaching 
            </p>
            <div className='d-flex read-center mt-5'>
            <button type="submit" class="btn read-more-btn"  style={{background:'#C3EAD8'}} onClick={handleClick8}>
              Read more <FaArrowRight />
            </button>
            </div>
          </div>
        );
      case "Kippo Campus":
        return (
          <div className="content">
            <img src={tech9} alt="Kippo Campus" className="carousel-image" />
            <h1>Kippo Campus</h1>
           
            <p className="sectionfour-para-2">
            EMPOWERING CONNECTIONS, ELEVATING EXPERIENCES & YOUR VOICE PARTNER PROCESS OF CHOICE
            </p>
            <div className='d-flex read-center mt-5'>
            <button type="submit" class="btn read-more-btn"  style={{backgroundColor:'#C3EAD8'}} onClick={handleClick9}>
              Read more <FaArrowRight />
            </button>
            </div>
          </div>
        );
    
   
      default:
        return null;
    }
  };

  return (
    <div className="SectionTwo SectionFour">
        <div className='container'>
<div className="row">
<p data-aos="fade-up" className="section-4-main-heading">Kevells</p>
          <p data-aos="fade-up" className="sectiontwo-para-1">
          Kevells is a company that provides IT services and solutions, and manufactures food processing machines and kitchen machines. 
          </p>
</div>
        </div>
         
      <div className="container-fluid SectionTwo-part-1">
        <div className="row">
       
          {/* <div className="col-lg-2 col-md-2 col-sm-12 col-12 mt-5"> */}
          <div className="autoplay-tab-carousel d-flex" data-aos="fade-up">
            <div className="tabs">
              {tabs.map((tab, index) => (
                <button
                  key={index}
                  className={activeTab === tab ? "active" : ""}
                  onClick={() => {
                    setActiveTab(tab);
                    setCurrentIndex(index);
                  }}
                >
                  {tab}
                </button>
              ))}
            </div>
            <div data-aos="fade-up" className="tab-content">{renderContent()}</div>
          </div>
          {/* </div> */}
          {/* <div className="col-lg-2 col-md-2 col-sm-12 col-12 mt-5">
           <img className="" src={bgimg2} alt="landingimagess" />
           </div>
          <div className="col-lg-10 col-md-10 col-sm-12 col-12 mt-5">            
           <p className="sectionfour-para-2">
           There are many things compared to which devops is described, many analogies, but the simple one would be "rowing a boat". It concisely represents the reliance that devops has on having both the right culture and tools. Culture- shared purpose and result across all project stakeholders. Tools- technology, process, framework</p>
           <button type="submit" class="btn read-more-btn">Read more <FaArrowRight /></button>
          </div> */}
        </div>
      </div>
    </div>
  );
}

export default Kevellsection;
