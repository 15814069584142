import React, {useEffect} from "react";
import { FaPhoneAlt } from "react-icons/fa";
import { MdOutlineMail } from "react-icons/md";
import { RxHamburgerMenu } from "react-icons/rx";
import { IoSearchOutline } from "react-icons/io5";
import { IoIosMore } from "react-icons/io";
import logo from "../../assets/Group 1392.png";
import { Navigate, NavLink, useNavigate } from "react-router-dom";
import AOS from 'aos';
import '../../utils/home.css'
import Footer from '../Footer/Footer'
import homepic from '../../assets/Group 4285.png'
import Kevellsection from './Kevellsection'
import Kevellcards from './Kevellcards'
import welpic from '../../assets/OBJECTS.png'
import flag from '../../assets/Component 264.png'
import char from '../../assets/Character.png'

function Homepage() {
  const navigate = useNavigate()
  const route = window.location.pathname

  useEffect(() => {
    AOS.init({
      duration : 2000
    });
    AOS.refresh();
  }, []);
  return (
    <div>
            <div className="herd-nav">
      {/* Bottom Section */}
      <nav className="navbar navbar-expand-lg">
        <div className="container-fluid">
      <NavLink to="/" className={({ isActive }) => (isActive ? "activetext" : "")}  style={{ textDecoration: 'none'}}>  
          <a className="navbar-brand">
            <img data-aos="fade-up"
              className="img-fluid"
              style={{ maxWidth: "175px" }}
              src={logo}
              alt="site-logo"
            />
            {/* Navbar */}
          </a>
          </NavLink>
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarNav"
            aria-controls="navbarNav"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon">
              <RxHamburgerMenu />
            </span>
          </button>
          <div className="collapse navbar-collapse" id="navbarNav">
            <ul data-aos="fade-up" className="navbar-nav ms-auto">
            <NavLink to="/" className={({ isActive }) => (isActive ? "activetext" : "")}  style={{ textDecoration: 'none'}}>  
            <li className="nav-item">
                <a className="nav-link" aria-current="page" href="#" >
                  Home
                </a>
              </li></NavLink>
              <NavLink to="/product" className={({ isActive }) => (isActive ? "activetext" : "")}  style={{ textDecoration: 'none'}}>  
              <li className="nav-item">
                <a className="nav-link" href="#">
                  Product
                </a>
              </li>
              </NavLink>
              <NavLink   to="/service" className={({ isActive }) => (isActive ? "activetext" : "")}  style={{ textDecoration: 'none'}}>  
              <li className="nav-item">
                <a className="nav-link" href="#">
                  Service
                </a>
              </li>
              </NavLink>
              {/* <NavLink to="/" className={({ isActive }) => (isActive ? "activetext" : "")}  style={{ textDecoration: 'none'}}>  
              <li className="nav-item">
                <a className="nav-link" href="#">
                  <IoSearchOutline size={25} />
                </a>
              </li>
              </NavLink> */}
              <form className="" style={{ paddingRight: "30px" }}>
                <button className="btn btn-sm get-start-btn" type="button">
                <NavLink to="/contact" className={({ isActive }) => (isActive ? "activetext" : "")} style={{ textDecoration: 'none',color:'black'}}> Contact</NavLink>
                </button>
              </form>
              <li className="nav-item">
                <a className="nav-link" href="#">
                  <IoIosMore size={25} />
                </a>
              </li>
            </ul>
          </div>
        </div>
      </nav>
      <div className="container mt-5">
            <div className='row'>
               <div className='col-sm-12 col-md-6 d-flex homepic-center'>
                    <img src={homepic} className="homepic"></img>
               </div>
               <div className='col-sm-12 col-md-6 d-flex justify-content-center flex-column'>
           <div className="d-flex  flex-column justify-content-end mt-3">
           <p className="sectionone-para-1 gradient-text">
                <span data-aos="fade-up" className="kevell-text">Kevell Corp</span> is an 
  
              </p>
              <h1 data-aos="fade-up" className="kevell-text">IT Development</h1> 
              <p data-aos="fade-up" className="sectionone-para-2 mt-2">
              service and solution providing company
              </p>
           </div>
              <div className='d-flex read-center mt-4 '>
              <button data-aos="fade-up" type="button" className="btn read-more-btn" style={{backgroundColor:'rgba(255, 248, 109, 1)'}}>
                Read more &gt;
              </button>
              </div>
            
               </div>
            </div>
      </div>

    </div>
    <div className="container mt-5 pt-3 backbg-welcome ">
        <div className="row">
          <div className='col-sm-12 col-md-6 d-flex justify-content-center flex-column p-5'>
            <h3 className="wel-text" data-aos="fade-up" >Welcome  to kevell Corp</h3>
             <p className="wel-para" data-aos="fade-up" >We strive to be the best place to work, fostering a strong, fun-loving corporate culture that attracts talent looking for long-term careers, not just a stepping-stone. Our focus is on helping customers meet their long-term needs and desires by providing world-class service</p>
             <div className='d-flex  mt-4 '>
              <button data-aos="fade-up" type="button" className="btn read-more-btn" style={{backgroundColor:'rgba(255, 248, 109, 1)'}}>
                Read more &gt;
              </button>
              </div>
          </div>
          <div className='col-sm-12 col-md-6 d-flex justify-content-center'>
        <img src={welpic}  className="homepics mt-2"data-aos="zoom-in" ></img>
</div>
        </div>
    </div>
    <Kevellsection/>
    <Kevellcards/>
{/*  
    <div className='container-fluid mt-5 step-bg'>
  <div className="row">
  <div className="p-5">
<h1 className="step-text">Our Journey</h1>
           </div>
    <div className=" col-sm-12 col-md-1 col-lg-1 col-xl-1 col-xxl-1 d-flex justify-content-start mt-5 flex-column step-1 ">
    <h4 style={{ color: 'blue',fontFamily:'Poppins' }} className="mb-3">*2014</h4>
    <ul className="mt-3 ul-color">
    <li>24/7 DevOps</li>
    <li>NOC Services</li>
  </ul>
  <svg xmlns="http://www.w3.org/2000/svg" width="150" height="82" viewBox="0 0 150 82" fill="none">
  <path d="M149.035 57.2461L0.227753 81.608V57.2461H149.035Z" fill="#BCBCBC"/>
  <path d="M0.00119019 39H148.809V57.2462H0.00119019V39Z" fill="#FFF86D"/>
  <g opacity="0.3">
    <path d="M0.00180435 39H46.4375V57.2462H0.00180435V39Z" fill="black"/>
  </g>
  <path d="M66.1406 12.7773C65.8279 13.0747 65.4231 13.2205 65.0183 13.2148C64.6135 13.209 64.2126 13.0536 63.9075 12.7467L63.8845 12.7236C63.5967 12.4205 63.4567 12.033 63.4624 11.6474C63.4682 11.2617 63.6197 10.878 63.9171 10.5826C67.3033 7.19452 70.747 3.84291 74.16 0.475951C74.1696 0.462521 74.1791 0.45101 74.1907 0.439499C74.5034 0.140214 74.912 -0.00559158 75.3168 0.000163909C75.7216 0.0059194 76.1245 0.163236 76.4295 0.470195L76.4794 0.521995C79.8464 3.87744 83.2229 7.2233 86.5822 10.5845C86.8777 10.88 87.0292 11.2617 87.035 11.6474C87.0407 12.033 86.9007 12.4205 86.611 12.7236L86.5822 12.7524C86.2791 13.0536 85.8801 13.209 85.4772 13.2148C85.0743 13.2205 84.6676 13.0747 84.3548 12.7773L75.2861 3.71821L66.1406 12.7773ZM66.5243 22.7094C66.2135 23.0049 65.8087 23.1487 65.4059 23.143C65.0011 23.1372 64.6001 22.9818 64.2951 22.6749L64.272 22.6518C63.9843 22.3506 63.8442 21.9612 63.848 21.5756C63.8538 21.19 64.0054 20.8043 64.3027 20.5108C67.6198 17.1956 70.8812 13.7673 74.158 10.4061C74.1676 10.3927 74.1791 10.3792 74.1907 10.3658C74.5034 10.0684 74.912 9.92263 75.3168 9.92838C75.7216 9.93414 76.1245 10.0915 76.4295 10.3984C79.6814 13.7558 82.9217 17.1266 86.1697 20.4897L86.1947 20.5127C86.4901 20.8063 86.6398 21.19 86.6455 21.5756C86.6513 21.9612 86.5112 22.3487 86.2215 22.6518L86.1928 22.6806C85.8896 22.9818 85.4906 23.1372 85.0877 23.143C84.6848 23.1487 84.2781 23.0029 83.9654 22.7056L75.2861 13.6541L66.5243 22.7094Z" fill="#D3D3CD"/>
</svg>
    </div>
    <div className=" col-sm-12 col-md-2  col-lg-2 col-xl-2 col-xxl-2 d-flex justify-content-start mt-5 flex-column step-2">
    <h4 style={{ color: 'blue',fontFamily:'Poppins' }} className="mb-3">*2016</h4>
    <ul className="mt-3 ul-color">
    <li>24/7 DevOps</li>
    <li>NOC Services</li>
    <li> Software Dvelopment</li>
  </ul>
  <svg xmlns="http://www.w3.org/2000/svg" width="218" height="97" viewBox="0 0 218 97" fill="none">
  <path d="M217.406 60.875L0.329666 96.4136V60.875H217.406Z" fill="#BCBCBC"/>
  <path d="M0.00154114 34.2578H217.078V60.8749H0.00154114V34.2578Z" fill="#FFF86D"/>
  <g opacity="0.3">
    <path d="M-0.000976562 34.2578H67.7383V60.8749H-0.000976562V34.2578Z" fill="black"/>
  </g>
  <path d="M99.818 13.4357C99.4891 13.7484 99.0635 13.9017 98.6378 13.8957C98.2122 13.8896 97.7905 13.7262 97.4698 13.4034L97.4456 13.3792C97.143 13.0605 96.9957 12.653 97.0017 12.2475C97.0078 11.842 97.1672 11.4385 97.4799 11.1279C101.04 7.56522 104.662 4.04092 108.25 0.500474C108.261 0.486353 108.271 0.474249 108.283 0.462145C108.612 0.147439 109.041 -0.00587969 109.467 0.000172354C109.893 0.00622439 110.316 0.171647 110.637 0.494422L110.689 0.548891C114.23 4.07723 117.78 7.59548 121.313 11.1299C121.623 11.4405 121.783 11.842 121.789 12.2475C121.795 12.653 121.648 13.0605 121.343 13.3792L121.313 13.4095C120.994 13.7262 120.574 13.8896 120.151 13.8957C119.727 13.9017 119.299 13.7484 118.971 13.4357L109.435 3.90979L99.818 13.4357ZM100.221 23.8795C99.8946 24.1902 99.469 24.3415 99.0453 24.3354C98.6197 24.3294 98.198 24.166 97.8773 23.8432L97.8531 23.819C97.5505 23.5023 97.4032 23.0927 97.4072 22.6873C97.4133 22.2818 97.5727 21.8763 97.8853 21.5676C101.373 18.0817 104.803 14.4767 108.248 10.9423C108.259 10.9281 108.271 10.914 108.283 10.8999C108.612 10.5872 109.041 10.4339 109.467 10.4399C109.893 10.446 110.316 10.6114 110.637 10.9342C114.056 14.4645 117.464 18.009 120.879 21.5454L120.905 21.5696C121.216 21.8783 121.373 22.2818 121.379 22.6873C121.385 23.0927 121.238 23.5002 120.934 23.819L120.903 23.8492C120.585 24.166 120.165 24.3294 119.741 24.3354C119.318 24.3415 118.89 24.1882 118.561 23.8755L109.435 14.3576L100.221 23.8795Z" fill="#D3D3CD"/>
</svg>
    </div>
    <div className=" col-sm-12 col-md-2  col-lg-2 col-xl-2 col-xxl-2 d-flex justify-content-start mt-5 flex-column step-3">
    <h4 style={{ color: 'blue',fontFamily:'Poppins' }} className="mb-3">*2018</h4>
    <ul className="mt-3 ul-color">
    <li>24/7 DevOps</li>
    <li>NOC Services</li>
   <li>Software Development</li> 
<li>Product Prototype</li>
<li>Engaged with Partners</li>

   
  </ul>
  <svg xmlns="http://www.w3.org/2000/svg" width="218" height="97" viewBox="0 0 218 97" fill="none">
  <path d="M217.406 60.875L0.329666 96.4136V60.875H217.406Z" fill="#BCBCBC"/>
  <path d="M0.00154114 34.2578H217.078V60.8749H0.00154114V34.2578Z" fill="#FFF86D"/>
  <g opacity="0.3">
    <path d="M-0.000976562 34.2578H67.7383V60.8749H-0.000976562V34.2578Z" fill="black"/>
  </g>
  <path d="M99.818 13.4357C99.4891 13.7484 99.0635 13.9017 98.6378 13.8957C98.2122 13.8896 97.7905 13.7262 97.4698 13.4034L97.4456 13.3792C97.143 13.0605 96.9957 12.653 97.0017 12.2475C97.0078 11.842 97.1672 11.4385 97.4799 11.1279C101.04 7.56522 104.662 4.04092 108.25 0.500474C108.261 0.486353 108.271 0.474249 108.283 0.462145C108.612 0.147439 109.041 -0.00587969 109.467 0.000172354C109.893 0.00622439 110.316 0.171647 110.637 0.494422L110.689 0.548891C114.23 4.07723 117.78 7.59548 121.313 11.1299C121.623 11.4405 121.783 11.842 121.789 12.2475C121.795 12.653 121.648 13.0605 121.343 13.3792L121.313 13.4095C120.994 13.7262 120.574 13.8896 120.151 13.8957C119.727 13.9017 119.299 13.7484 118.971 13.4357L109.435 3.90979L99.818 13.4357ZM100.221 23.8795C99.8946 24.1902 99.469 24.3415 99.0453 24.3354C98.6197 24.3294 98.198 24.166 97.8773 23.8432L97.8531 23.819C97.5505 23.5023 97.4032 23.0927 97.4072 22.6873C97.4133 22.2818 97.5727 21.8763 97.8853 21.5676C101.373 18.0817 104.803 14.4767 108.248 10.9423C108.259 10.9281 108.271 10.914 108.283 10.8999C108.612 10.5872 109.041 10.4339 109.467 10.4399C109.893 10.446 110.316 10.6114 110.637 10.9342C114.056 14.4645 117.464 18.009 120.879 21.5454L120.905 21.5696C121.216 21.8783 121.373 22.2818 121.379 22.6873C121.385 23.0927 121.238 23.5002 120.934 23.819L120.903 23.8492C120.585 24.166 120.165 24.3294 119.741 24.3354C119.318 24.3415 118.89 24.1882 118.561 23.8755L109.435 14.3576L100.221 23.8795Z" fill="#D3D3CD"/>
</svg>
    </div>
    <div className=" col-sm-12 col-md-2   col-lg-2 col-xl-2 col-xxl-2 d-flex justify-content-start mt-5 flex-column step-4">
    <h4 style={{ color: 'blue',fontFamily:'Poppins' }} className="mb-3">*2020</h4>
    <ul className="mt-3 ul-color">
    <li>24/7 DevOps</li>
    <li>NOC Services</li>
    <li>Software Development</li> 
<li>Product Prototype</li>
<li>Engaged with Partners</li>
<li>Global Presence </li>
<li>ISO Certified</li>
<li> US & Singapore Operations</li>
  </ul>
  <svg xmlns="http://www.w3.org/2000/svg" width="218" height="97" viewBox="0 0 218 97" fill="none">
  <path d="M217.406 60.875L0.329666 96.4136V60.875H217.406Z" fill="#BCBCBC"/>
  <path d="M0.00154114 34.2578H217.078V60.8749H0.00154114V34.2578Z" fill="#FFF86D"/>
  <g opacity="0.3">
    <path d="M-0.000976562 34.2578H67.7383V60.8749H-0.000976562V34.2578Z" fill="black"/>
  </g>
  <path d="M99.818 13.4357C99.4891 13.7484 99.0635 13.9017 98.6378 13.8957C98.2122 13.8896 97.7905 13.7262 97.4698 13.4034L97.4456 13.3792C97.143 13.0605 96.9957 12.653 97.0017 12.2475C97.0078 11.842 97.1672 11.4385 97.4799 11.1279C101.04 7.56522 104.662 4.04092 108.25 0.500474C108.261 0.486353 108.271 0.474249 108.283 0.462145C108.612 0.147439 109.041 -0.00587969 109.467 0.000172354C109.893 0.00622439 110.316 0.171647 110.637 0.494422L110.689 0.548891C114.23 4.07723 117.78 7.59548 121.313 11.1299C121.623 11.4405 121.783 11.842 121.789 12.2475C121.795 12.653 121.648 13.0605 121.343 13.3792L121.313 13.4095C120.994 13.7262 120.574 13.8896 120.151 13.8957C119.727 13.9017 119.299 13.7484 118.971 13.4357L109.435 3.90979L99.818 13.4357ZM100.221 23.8795C99.8946 24.1902 99.469 24.3415 99.0453 24.3354C98.6197 24.3294 98.198 24.166 97.8773 23.8432L97.8531 23.819C97.5505 23.5023 97.4032 23.0927 97.4072 22.6873C97.4133 22.2818 97.5727 21.8763 97.8853 21.5676C101.373 18.0817 104.803 14.4767 108.248 10.9423C108.259 10.9281 108.271 10.914 108.283 10.8999C108.612 10.5872 109.041 10.4339 109.467 10.4399C109.893 10.446 110.316 10.6114 110.637 10.9342C114.056 14.4645 117.464 18.009 120.879 21.5454L120.905 21.5696C121.216 21.8783 121.373 22.2818 121.379 22.6873C121.385 23.0927 121.238 23.5002 120.934 23.819L120.903 23.8492C120.585 24.166 120.165 24.3294 119.741 24.3354C119.318 24.3415 118.89 24.1882 118.561 23.8755L109.435 14.3576L100.221 23.8795Z" fill="#D3D3CD"/>
</svg>
    </div>
    <div className=" col-sm-12 col-md-2   col-lg-2 col-xl-2 col-xxl-2 d-flex justify-content-start mt-5 flex-column step-5">
    <h4 style={{ color: 'blue',fontFamily:'Poppins' }} className="mb-3">*2022</h4>
    <ul className="mt-3 ul-color">
    <li>DevOps</li>
    <li>IoT</li>
    <li>AR/VR</li>
    <li>Big Data</li>
    <li>Data Science</li>
    <li>Mobile App Development</li>
    <li>Web Development</li>
    <li>Managed Service Provider</li>
    <li>Security</li>
    <li>Animation</li>
    <li>Digital Marketing</li>
    <li>UI/UX Design</li>
    <li>Graphics Design</li>
    <li>HR Services</li>
    <li>Finance Services</li>
  </ul>
  <svg xmlns="http://www.w3.org/2000/svg" width="218" height="97" viewBox="0 0 218 97" fill="none">
  <path d="M217.406 60.875L0.329666 96.4136V60.875H217.406Z" fill="#BCBCBC"/>
  <path d="M0.00154114 34.2578H217.078V60.8749H0.00154114V34.2578Z" fill="#FFF86D"/>
  <g opacity="0.3">
    <path d="M-0.000976562 34.2578H67.7383V60.8749H-0.000976562V34.2578Z" fill="black"/>
  </g>
  <path d="M99.818 13.4357C99.4891 13.7484 99.0635 13.9017 98.6378 13.8957C98.2122 13.8896 97.7905 13.7262 97.4698 13.4034L97.4456 13.3792C97.143 13.0605 96.9957 12.653 97.0017 12.2475C97.0078 11.842 97.1672 11.4385 97.4799 11.1279C101.04 7.56522 104.662 4.04092 108.25 0.500474C108.261 0.486353 108.271 0.474249 108.283 0.462145C108.612 0.147439 109.041 -0.00587969 109.467 0.000172354C109.893 0.00622439 110.316 0.171647 110.637 0.494422L110.689 0.548891C114.23 4.07723 117.78 7.59548 121.313 11.1299C121.623 11.4405 121.783 11.842 121.789 12.2475C121.795 12.653 121.648 13.0605 121.343 13.3792L121.313 13.4095C120.994 13.7262 120.574 13.8896 120.151 13.8957C119.727 13.9017 119.299 13.7484 118.971 13.4357L109.435 3.90979L99.818 13.4357ZM100.221 23.8795C99.8946 24.1902 99.469 24.3415 99.0453 24.3354C98.6197 24.3294 98.198 24.166 97.8773 23.8432L97.8531 23.819C97.5505 23.5023 97.4032 23.0927 97.4072 22.6873C97.4133 22.2818 97.5727 21.8763 97.8853 21.5676C101.373 18.0817 104.803 14.4767 108.248 10.9423C108.259 10.9281 108.271 10.914 108.283 10.8999C108.612 10.5872 109.041 10.4339 109.467 10.4399C109.893 10.446 110.316 10.6114 110.637 10.9342C114.056 14.4645 117.464 18.009 120.879 21.5454L120.905 21.5696C121.216 21.8783 121.373 22.2818 121.379 22.6873C121.385 23.0927 121.238 23.5002 120.934 23.819L120.903 23.8492C120.585 24.166 120.165 24.3294 119.741 24.3354C119.318 24.3415 118.89 24.1882 118.561 23.8755L109.435 14.3576L100.221 23.8795Z" fill="#D3D3CD"/>
</svg>
    </div>
    <div className=" col-sm-12 col-md-2   col-lg-2 col-xl-2 col-xxl-2-1 d-flex justify-content-start mt-5 flex-column step-6">
    <h4 style={{ color: 'blue',fontFamily:'Poppins' }} className="mb-3">*2025</h4>
    <ul className="mt-3 ul-color">
    <li>T & ITES - Development and Support</li>
    <li>Working Industries: Healthcare, Energy, Agritech, Education, Industrial Automation</li>
    <li>AI / ML Solutions</li>
    <li>Establishing in: India, USA, Canada, Germany, Japan, Dubai</li>
  </ul>
  <svg xmlns="http://www.w3.org/2000/svg" width="218" height="97" viewBox="0 0 218 97" fill="none">
  <path d="M217.406 60.875L0.329666 96.4136V60.875H217.406Z" fill="#BCBCBC"/>
  <path d="M0.00154114 34.2578H217.078V60.8749H0.00154114V34.2578Z" fill="#FFF86D"/>
  <g opacity="0.3">
    <path d="M-0.000976562 34.2578H67.7383V60.8749H-0.000976562V34.2578Z" fill="black"/>
  </g>
  <path d="M99.818 13.4357C99.4891 13.7484 99.0635 13.9017 98.6378 13.8957C98.2122 13.8896 97.7905 13.7262 97.4698 13.4034L97.4456 13.3792C97.143 13.0605 96.9957 12.653 97.0017 12.2475C97.0078 11.842 97.1672 11.4385 97.4799 11.1279C101.04 7.56522 104.662 4.04092 108.25 0.500474C108.261 0.486353 108.271 0.474249 108.283 0.462145C108.612 0.147439 109.041 -0.00587969 109.467 0.000172354C109.893 0.00622439 110.316 0.171647 110.637 0.494422L110.689 0.548891C114.23 4.07723 117.78 7.59548 121.313 11.1299C121.623 11.4405 121.783 11.842 121.789 12.2475C121.795 12.653 121.648 13.0605 121.343 13.3792L121.313 13.4095C120.994 13.7262 120.574 13.8896 120.151 13.8957C119.727 13.9017 119.299 13.7484 118.971 13.4357L109.435 3.90979L99.818 13.4357ZM100.221 23.8795C99.8946 24.1902 99.469 24.3415 99.0453 24.3354C98.6197 24.3294 98.198 24.166 97.8773 23.8432L97.8531 23.819C97.5505 23.5023 97.4032 23.0927 97.4072 22.6873C97.4133 22.2818 97.5727 21.8763 97.8853 21.5676C101.373 18.0817 104.803 14.4767 108.248 10.9423C108.259 10.9281 108.271 10.914 108.283 10.8999C108.612 10.5872 109.041 10.4339 109.467 10.4399C109.893 10.446 110.316 10.6114 110.637 10.9342C114.056 14.4645 117.464 18.009 120.879 21.5454L120.905 21.5696C121.216 21.8783 121.373 22.2818 121.379 22.6873C121.385 23.0927 121.238 23.5002 120.934 23.819L120.903 23.8492C120.585 24.166 120.165 24.3294 119.741 24.3354C119.318 24.3415 118.89 24.1882 118.561 23.8755L109.435 14.3576L100.221 23.8795Z" fill="#D3D3CD"/>
</svg>
<img src={char} className="charpic"></img>
    </div>

    <div className=" col-sm-12 col-md-1  col-lg-1 col-xl-1 col-xxl-1 d-flex justify-content-start mt-5 flex-column step-7 ">
    <div className="flag-container">
      <img src={flag} alt="Flag" className="waving-flag" />
    </div>
    <svg xmlns="http://www.w3.org/2000/svg" width="202" height="58" viewBox="0 0 202 58" fill="none">
  <path d="M201.512 24.707L0.000305176 57.6956V24.707H201.512Z" fill="#BCBCBC"/>
  <path d="M0.00177002 0H201.512V24.7083H0.00177002V0Z" fill="#FFF86D"/>
</svg>
    </div>
  </div>
</div> */}


          <Footer/>


    </div>

  
  );
}

export default Homepage;
